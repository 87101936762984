import "./EventsGalleryCard1.css";
import "../EventGalleryCard.css";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../../functions/DateFunctions";
import { TrimToNumOfCharacters } from "../../../../../../functions/TextFunctions";
const EventsGalleryCard1 = ({ data, order }) => {
  return (
    <div
      className={
        order === "left"
          ? "eventsgallerycard1-container egc1-row"
          : "eventsgallerycard1-container egc1-row-rev"
      }
    >
      <div className="eventsgallerycard1-image-container">
        <img
          src={Array.isArray(data.image) ? data.image[0] : null}
          alt="events-img-1"
          className="eventsgallerycard1-img-1"
        />
        <img
          src={Array.isArray(data.image) ? data.image[0] : null}
          alt="events-img-1"
          className="eventsgallerycard1-img-1 eventsgallerycard1-img-1-duplicate"
        />
        <img
          src={
            Array.isArray(data.image)
              ? data?.image.length === 1
                ? data.image[0]
                : data.image[1]
              : null
          }
          alt="events-img-2"
          className="eventsgallerycard1-img-2"
        />
        <img
          src={
            Array.isArray(data.image)
              ? data?.image.length === 1
                ? data.image[0]
                : data.image[1]
              : null
          }
          alt="events-img-2"
          className="eventsgallerycard1-img-2 eventsgallerycard1-img-2-duplicate"
        />
      </div>
      <div className="eventsgallerycard1-desc-container">
        <div className="event-gallery-card-heading">{data?.name}</div>
        <div className="event-gallery-card-description">
          {TrimToNumOfCharacters(350, data?.description)}
        </div>
        <Link className="event-gallery-card-button" to={data?._id}>
          {data?.createdAt ? formatDate(data?.createdAt) : "More"}
        </Link>
      </div>
    </div>
  );
};

export default EventsGalleryCard1;

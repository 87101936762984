import PersonImage from "../../../../assets/simpleLayout/home/testimonial/PersonImage1.png";
import PersonImage2 from "../../../../assets/simpleLayout/home/testimonial/PersonImage2.png";
export const TestimonialData = [
  {
    personImage: PersonImage,
    name: "Zendaya",
    position: "CEO at Xion",
    review:
      "We had an incredible experience working with NilaviTech and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the product concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.",
  },
  {
    personImage: PersonImage2,
    name: "Lorem",
    position: "CEO at Lorem",
    review:
      "NilaviTech and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the product concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.",
  },
  {
    personImage: PersonImage,
    name: "Ipsum",
    position: "CEO at Ipsum",
    review:
      "We had an incredible experience working with NilaviTech and were impressed they made such a big difference in only three weeks. Our team is so grateful for the wonderful improvements they made and their ability to get familiar with the product concept so quickly. It acted as a catalyst to take our design to the next level and get more eyes on our product.",
  },
];

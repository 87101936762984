import React from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboardLayout/DashboardLayout";
import Home from "./pages/simpleLayoutPages/Home";
import SimpleLayout from "./layouts/simpleLayout/SimpleLayout";
import AboutPage from "./pages/simpleLayoutPages/AboutPage";
import ServicesPage from "./pages/simpleLayoutPages/ServicesPage";
import BlogPage from "./pages/simpleLayoutPages/Blogs/BlogPage";
import BlogDescriptionPage from "./pages/simpleLayoutPages/Blogs/BlogDescriptionPage";
import AllCareersPage from "./pages/simpleLayoutPages/Careers/AllCareersPage";
import CarrierBriefPage from "./pages/simpleLayoutPages/Careers/CarrierBriefPage";
import ContactUsPage from "./pages/simpleLayoutPages/ContactUsPage";
import DashboardPage from "./pages/dashboardLayoutPages/Dashboard/DashboardPage";
import DashboardServicesPage from "./pages/dashboardLayoutPages/Services/DashboardServicesPage";
import DashboardAddServicesPage from "./pages/dashboardLayoutPages/Services/DashboardAddServicesPage";
import DashboardCareersPage from "./pages/dashboardLayoutPages/Careers/DashboardCareersPage";
import DashboardAddCareerPage from "./pages/dashboardLayoutPages/Careers/DashboardAddCareerPage";
import DashboardEventsPage from "./pages/dashboardLayoutPages/Events/DashboardEventsPage";
import DashboardAddEventsPage from "./pages/dashboardLayoutPages/Events/DashboardAddEventsPage";
import DashboardPortfolioPage from "./pages/dashboardLayoutPages/portfolio/DashboardPortfolioPage";
import DashboardAddPortfolioPage from "./pages/dashboardLayoutPages/portfolio/DashboardAddPortfolioPage";
import DashboardBlogPage from "./pages/dashboardLayoutPages/Blog/DashboardBlogPage";
import DashboardAddBlogPage from "./pages/dashboardLayoutPages/Blog/DashboardAddBlogPage";
import DashboardReviewPage from "./pages/dashboardLayoutPages/Reviews/DashboardReviewPage";
import DashboardAddReviewsPage from "./pages/dashboardLayoutPages/Reviews/DashboardAddReviewsPage";
import DashboardTeamPage from "./pages/dashboardLayoutPages/Teams/DashboardTeamPage";
import DashboardAddTeamsPage from "./pages/dashboardLayoutPages/Teams/DashboardAddTeamsPage";
import PortfolioPage from "./pages/simpleLayoutPages/Portfolio/PortfolioPage";
import PortfolioBriefPage from "./pages/simpleLayoutPages/Portfolio/PortfolioBriefPage";
import EventDescriptionPage from "./pages/simpleLayoutPages/Events/EventDescriptionPage";
import EventsPage from "./pages/simpleLayoutPages/Events/EventsPage";
import DashboardEditServicePage from "./pages/dashboardLayoutPages/Services/DashboardEditServicePage";
import DashboardEditCareerPage from "./pages/dashboardLayoutPages/Careers/DashboardEditCareerPage";
import DashboardEditPortfolioPage from "./pages/dashboardLayoutPages/portfolio/DashboardEditPortfolioPage";
import DashboardEditReviewsPage from "./pages/dashboardLayoutPages/Reviews/DashboardEditReviewsPage";
import DashboardEditTeamsPage from "./pages/dashboardLayoutPages/Teams/DashboardEditTeamsPage";
import DashboardEditEventPage from "./pages/dashboardLayoutPages/Events/DashboardEditEventPage";
import DashboardEditBlogPage from "./pages/dashboardLayoutPages/Blog/DashboardEditBlogPage";
import LoginPage from "./pages/LoginPage";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { showToastMessage } from "./redux/actions/toastActions";
export default function Router() {
  function isTokenExpired(token) {
    if (!token) {
      return true;
    }
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Invalid token:", error);
      return true;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isAuthenticated = false;
  if (localStorage.getItem("token")) {
    if (isTokenExpired(localStorage.getItem("token")) === true) {
      isAuthenticated = false;
      localStorage.removeItem("token");
      dispatch(showToastMessage("Session Timeout", "warningToast"));
      navigate("/login");
    } else {
      isAuthenticated = true;
    }
  } else {
    isAuthenticated = false;
  }
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: isAuthenticated ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "", element: <DashboardPage /> },
        // Services Management
        { path: "services", element: <DashboardServicesPage /> },
        { path: "services/add", element: <DashboardAddServicesPage /> },
        {
          path: "services/edit/:serviceId",
          element: <DashboardEditServicePage />,
        },
        // Career Management
        { path: "career", element: <DashboardCareersPage /> },
        { path: "career/add", element: <DashboardAddCareerPage /> },
        { path: "career/edit/:id", element: <DashboardEditCareerPage /> },
        // Events Management
        { path: "events", element: <DashboardEventsPage /> },
        {
          path: "events/add",
          element: <DashboardAddEventsPage />,
        },
        {
          path: "events/edit/:id",
          element: <DashboardEditEventPage />,
        },
        // Portfolio Management
        { path: "portfolio", element: <DashboardPortfolioPage /> },
        { path: "portfolio/add", element: <DashboardAddPortfolioPage /> },
        { path: "portfolio/edit/:id", element: <DashboardEditPortfolioPage /> },
        // Blog Management
        { path: "blogs", element: <DashboardBlogPage /> },
        { path: "blogs/add", element: <DashboardAddBlogPage /> },
        { path: "blogs/edit/:id", element: <DashboardEditBlogPage /> },
        // Reviews Management
        { path: "reviews", element: <DashboardReviewPage /> },
        { path: "reviews/add", element: <DashboardAddReviewsPage /> },
        { path: "reviews/edit/:id", element: <DashboardEditReviewsPage /> },
        // Teams Management
        { path: "teams", element: <DashboardTeamPage /> },
        { path: "teams/add", element: <DashboardAddTeamsPage /> },
        { path: "teams/edit/:id", element: <DashboardEditTeamsPage /> },
      ],
    },
    {
      path: "",
      element: <SimpleLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <AboutPage /> },
        { path: "/services", element: <ServicesPage /> },
        { path: "/blogs", element: <BlogPage /> },
        { path: "/blogs/:id", element: <BlogDescriptionPage /> },
        { path: "/careers", element: <AllCareersPage /> },
        { path: "/careers/:careerId", element: <CarrierBriefPage /> },
        { path: "/contactus", element: <ContactUsPage /> },
        { path: "/portfolio", element: <PortfolioPage /> },
        { path: "/portfolio/:id", element: <PortfolioBriefPage /> },
        { path: "/events", element: <EventsPage /> },
        { path: "/events/:id", element: <EventDescriptionPage /> },
      ],
    },
    {
      path: "/login",
      element: isAuthenticated ? (
        <Navigate to="/dashboard" replace />
      ) : (
        <LoginPage />
      ),
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  return routes;
}

import React, { useEffect, useState } from "react";
import Polygon from "../../../../components/Polygon/Polygon";
import CareerLanding from "../CareerLanding";
import "./CareerBrief.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
const CareerBrief = ({ careerId }) => {
  const handleInput = (event) => {
    event.target.parentNode.dataset.replicatedValue = event.target.value;
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [careerData, setCareerData] = useState({});
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  useEffect(() => {
    try {
      dispatch(startLoading());
      axios
        .get("/api/career/" + careerId)
        .then((res) => {
          setCareerData(res.data);
          dispatch(stopLoading());
        })
        .catch((err) => {
          console.log(err);
          dispatch(stopLoading());
        });
    } catch (error) {
      console.log(error);
      dispatch(stopLoading());
    }
  }, []);

  const submitApplication = () => {
    const validationConfig = [
      {
        name: "pdf",
        type: "pdf",
        count: 1,
        validations: ["required"],
        label: "PDF",
      },
      {
        name: "fullname",
        validations: ["required"],
        label: "Full Name",
      },
      {
        name: "email",
        validations: ["required"],
        label: "Email",
      },
      {
        name: "phone",
        validations: ["required"],
        label: "Phone",
      },
      {
        name: "aboutyou",
        validations: ["required"],
        label: "About You",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      let bodyData = new FormData();
      bodyData.append("fullname", formData.fullname);
      bodyData.append("email", formData.email);
      bodyData.append("phone", formData.phone);
      bodyData.append("about", formData.aboutyou);
      bodyData.append("pdf", formData.pdf);
      bodyData.append("career", window.location.href);
      try {
        dispatch(startLoading());
        axios
          .post("/api/general/jobapplication", bodyData)
          .then((res) => {
            toast("Applied Successfully", "successToast");
            setFormData({
              requirements: "",
              expertise: "",
              image: [],
              jobRole: "",
            });
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Applying", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };

  return (
    <section>
      <CareerLanding />
      <div className="career-brief-polygon">
        <Polygon
          marginLeft={"480px"}
          marginTop={"300px"}
          width={500}
          height={500}
        />
      </div>
      <div className="carrier-brief-container">
        <div className="carrier-brief-heading-container">
          <span className="carrier-brief-heading">{careerData.jobrole}</span>
        </div>
        <div className="carrier-desc-container">
          <div className="carrier-desc-container-sections">
            <div className="career-brief-desc-section">
              <div className="career-brief-desc-section-heading">
                Requirements
              </div>
              <div>
                <ul className="career-brief-list">
                  {careerData?.requirements
                    ? careerData.requirements
                        .split("\r\n")
                        .map((singleReq, index) => {
                          return <li key={index}>{singleReq}</li>;
                        })
                    : null}
                </ul>
              </div>
            </div>
            <div className="career-brief-desc-section">
              <div className="career-brief-desc-section-heading">
                Expertise:
              </div>
              <div>
                <ul className="career-brief-list">
                  {careerData?.expertise
                    ? careerData.expertise
                        .split("\r\n")
                        .map((singleReq, index) => {
                          return <li key={index}>{singleReq}</li>;
                        })
                    : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="carrier-desc-form-submission">
            <div className="carrer-submit-form">
              <div className="carrer-submit-form-heading">
                Submit Your Application
              </div>
              <div>
                <form className="career-brief-form">
                  <div>
                    <label className="form-label">Full Name</label>
                    <input
                      type="text"
                      className="form-controller"
                      value={formData?.fullname}
                      onChange={(e) => {
                        handleChange(e, "fullname");
                      }}
                    ></input>
                    <span className="form-error-message">
                      {errors?.fullname}
                    </span>
                  </div>
                  <div>
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-controller"
                      value={formData?.email}
                      onChange={(e) => {
                        handleChange(e, "email");
                      }}
                    ></input>
                    <span className="form-error-message">{errors?.email}</span>
                  </div>
                  <div>
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      className="form-controller"
                      value={formData?.phone}
                      onChange={(e) => {
                        handleChange(e, "phone");
                      }}
                    ></input>
                    <span className="form-error-message">{errors?.phone}</span>
                  </div>
                  <div>
                    <label className="form-label">About You</label>
                    <div className="grow-wrap">
                      <textarea
                        name="text"
                        className="form-controller"
                        rows={5}
                        onInput={handleInput}
                        value={formData?.aboutyou}
                        onChange={(e) => {
                          handleChange(e, "aboutyou");
                        }}
                      ></textarea>
                      <span className="form-error-message">
                        {errors?.aboutyou}
                      </span>
                    </div>
                  </div>

                  <div className="input-container form-controller">
                    <input
                      type="file"
                      id="fileUpload"
                      className="form-controller file-input"
                      onChange={(event) => {
                        handleChange(event, "pdf");
                        setSelectedFile(event.target.files[0]?.name);
                      }}
                    ></input>
                    
                      {selectedFile && (
                        <div className="file-path">{selectedFile}</div>
                      )}
                      <div>
                      <label htmlFor="fileUpload" className="file-label">
                        Browse
                      </label>
                      </div>
                      </div>
                    <span className="form-error-message">{errors?.pdf}</span>
                
                  <div className="career-brief-submit-button">
                    <button
                      type="button"
                      className="career-brief-submit"
                      onClick={submitApplication}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerBrief;

import { useState } from "react";
import "./Logout.css";
import { FaPowerOff } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const Logout = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <div className="logout-main-container">
      <img
        onClick={() => setOpenPopup(!openPopup)}
        src="https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
        alt="user"
        className="dashboard-header-loggedin-user-image"
      />
      <div
        className={
          openPopup
            ? "logut-container logut-container-active"
            : "logut-container"
        }
      >
        <button className="logout-buton-container" onClick={logout}>
          <FaPowerOff /> Logout
        </button>
      </div>
    </div>
  );
};

export default Logout;

import React from "react";
import "./Pagination.css";
import { FaCaretRight } from "react-icons/fa";
import { FaCaretLeft } from "react-icons/fa";
function Pagination({ currentPage, totalPage, paginate }) {
  // console.log(currentPage, totalPage, paginate);
  return (
    <div className="pagination-container">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <FaCaretLeft />
      </button>
      {Array.from({ length: Math.ceil(totalPage) }).map((_, index) => (
        <>
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        </>
      ))}
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalPage)}
      >
        <FaCaretRight />
      </button>
    </div>
  );
}

export default Pagination;

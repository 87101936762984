import "./OurServicesCard.css";
// import Icon1 from "../../../../assets/simpleLayout/services/icons/icon1.svg";
const OurServicesCard = ({ Icon, mainHeading, subHeading, description }) => {
  return (
    <div className="our-services-card-main-container">
      <img src={Icon} alt="service-1" className="our-service-card-icon"></img>
      <div className="our-services-card-main-heading">{mainHeading}</div>
      <div className="our-services-card-sub-heading">{subHeading}</div>
      <div className="our-services-card-description">{description}</div>
    </div>
  );
};

export default OurServicesCard;

import "./DashboardHeader.css";
import Logo from "../../../../assets/general/logo.png";
import { CiLocationOn } from "react-icons/ci";
import jwt_decode from "jwt-decode";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const DashboardHeader = () => {
  const navigate = useNavigate();
  let localstorageUser = localStorage.getItem("token");
  let user = "";
  if (localstorageUser) {
    user = jwt_decode(localstorageUser);
  } else {
    navigate("/login");
  }
  return (
    <div>
      <div className="dashboard-header-component-container">
        <div className="dashboard-header-circle-yellow"></div>
        <div className="dashboard-header-circle-orange"></div>
        <div className="dashboard-header-component-logo-container">
          {/* <img src={Logo} alt="logo" className="dashboard-header-logo" /> */}
          <div className="dashboard-header-user-name">
            Hi, {user?.firstName || "Admin"}
          </div>
          <div className="dashboard-header-user-role">Admin</div>
        </div>
        <div className="dashboard-header-info">
          {/* <div>
            <div className="dashboard-header-phone-text">Address</div>
            <div className="dashboard-header-address">
              <CiLocationOn />
              <span>Moratuwa, Srilanka</span>
            </div>
          </div> */}
          <div>
            <div className="dashboard-header-phone-text">Address</div>
            <div className="dashboard-header-phone">
              <div className="dashboard-header-icon">
                <CiLocationOn />
              </div>
              <div className="dashboard-header-phone-number">+94 778905998</div>
            </div>
          </div>
          <div>
            <div className="dashboard-header-phone-text">Phone</div>
            <div className="dashboard-header-phone">
              <div className="dashboard-header-icon">
                <IoCallOutline />
              </div>
              <div className="dashboard-header-phone-number">+94 778905998</div>
            </div>
          </div>
          <div>
            <div className="dashboard-header-email-text">Email</div>
            <div className="dashboard-header-email">
              <div className="dashboard-header-icon">
                <MdOutlineMailOutline />
              </div>
              <div className="dashboard-header-email-address">
                Nilavitech@mail.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardHeader;

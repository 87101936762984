import "./DashboardYourPlan.css";
import Vector from "./Vector.png";
const DashboardYourPlan = () => {
  return (
    <div className="dashboard-your-plan-container">
      <img src={Vector} className="vector-your-plan" alt="plan" />
    </div>
  );
};
export default DashboardYourPlan;

import React, { useEffect, useState } from "react";
import "./Carousel.css";
import axios from "axios";

const Carousel = ({ setIsError }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    axios
      .get("/api/clients", {
        params: {
          limit: 20,
          page: 1,
        },
      })
      .then((res) => {
        setClients(res.data?.data);
      })
      .catch((err) => {
        setIsError(true);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      const logosSlide = document.querySelector(".logos-slide");
      const copy = logosSlide.cloneNode(true);
      document.querySelector(".logos").appendChild(copy);
    }
  }, [clients]);

  return (
    <div className="home-carousel-container">
      <div className="logos">
        <div className="logos-slide">
          {Array.isArray(clients)
            ? clients.map((client, index) => {
                return <img src={client.image} alt={client.name} key={index} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Carousel;

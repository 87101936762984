import { useState } from "react";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import "./Services.css";
import ServicesCard from "./ServicesCard/ServicesCard";
import axios from "axios";
import { useEffect } from "react";
import { TrimToNumOfCharacters } from "../../../../functions/TextFunctions";
import { Link } from "react-router-dom";
const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    try {
      axios
        .get("/api/services?limit=4&page=1")
        .then((response) => {
          setServicesData(response.data.data);
        })
        .catch((error) => {
          setIsError(true);
          console.log(error);
        });
    } catch (error) {
      setIsError(true);
      console.log(error);
    }
  }, []);

  return (
    !isError && (
      <>
        <div className="services-header home-top-space">
          <div style={{ position: "relative" }} className="services-landing-curved-line">
            <CurvedLines
              width={1800}
              height={388}
              top={"-55px"}
              zIndex={1}
              left={"70px"}
              cliptop={"55px"}
              color={"#575757"}
              key="2"
            />
          </div>
          <section id="services-home-header">
            <div className="services-header-container">
              <div style={{ zIndex: "2" }}>
                <div className="services-main-small-heading">
                  <span className="services-sub-heading">Services</span>
                </div>
                <div className="services-main-medium-heading">
                  <span className="services-main-heading">
                    We Offer a Wide Variety Of{" "}
                    <br className="service-main-heading-break"></br> IT Services
                  </span>
                  <span className="services-main-heading-contd">.</span>
                </div>
              </div>
              <div>
                <Link to={"/services"} style={{ textDecoration: "none" }}>
                  <button className="services-header-button">View All</button>
                </Link>
              </div>
            </div>
          </section>
        </div>
        <section id="home-all-services" className={"services-card-top"}>
          <div className="available-services-container">
            {Array.isArray(servicesData)
              ? servicesData.map((service) => {
                  return (
                    <div className="services-card">
                      <ServicesCard
                        servicesMainSvg={service.image}
                        mainHeading={service.name}
                        subHeading={service.subtitle}
                        description={TrimToNumOfCharacters(
                          106,
                          service.description
                        )}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </section>
      </>
    )
  );
};

export default Services;

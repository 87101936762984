import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import toastReducer from "./reducers/toastReducer";
import loaderReducer from "./reducers/loaderReducer";
const rootReducer = combineReducers({
  toast: toastReducer,
  loader: loaderReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

import "./BlogPreview.css";
import { Link } from "react-router-dom";
const BlogPreview = ({
  isNew,
  heading,
  description,
  readMoreLink,
  viewCount,
  image,
}) => {
  return (
    <div className="blog-preview-container">
      <div className="blog-preview-image-container">
        <img src={image} className="blog-preview-image" alt="blog-view" />
      </div>
      <div className="blog-preview-is-new">
        {isNew ? "New" : <div>&nbsp;</div>}{" "}
      </div>
      <div className="blog-preview-main-heading">{heading}</div>
      <div className="blog-preview-description">{description}</div>
      <div className="blog-preview-footer">
        <div className="blog-preview-view-count">{viewCount}</div>
        <div className="blog-preview-read-button">
          <Link
            style={{ textDecoration: "none" }}
            to={readMoreLink}
          >
            Read Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPreview;

import { useParams } from "react-router-dom";
import EventsDescription from "../../../containers/simpleLayout/events/eventsDescription/EventsDescription";

const EventDescriptionPage = () => {
  const { id } = useParams();
  return (
    <>
      <EventsDescription id={id} />
    </>
  );
};
export default EventDescriptionPage;

import "./EventsGalleryCard2.css";
import "../EventGalleryCard.css";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../../functions/DateFunctions";
import { TrimToNumOfCharacters } from "../../../../../../functions/TextFunctions";
const EventGalleryCard2 = ({ data, order }) => {
  return (
    <div
      className={
        order === "left"
          ? "eventsgallerycard2-container egc2-row-rev"
          : "eventsgallerycard2-container egc2-row"
      }
    >
      <div className="eventsgallerycard2-desc-container">
        <div className="event-gallery-card-heading">{data.name}</div>
        <div className="event-gallery-card-description">
          {TrimToNumOfCharacters(300, data.description)}
        </div>
        <Link className="event-gallery-card-button" to={data?._id}>
          {data?.createdAt ? formatDate(data?.createdAt) : "More"}
        </Link>
      </div>

      <div className="eventsgallerycard2-image-container">
        {/* Image 1 */}
        <img
          src={data?.image[0]}
          alt="events-img-1"
          className="eventsgallerycard2-img-1"
        />
        <img
          src={data?.image[0]}
          alt="events-img-1"
          className="eventsgallerycard2-img-1 eventsgallerycard2-img-1-duplicate"
        />
        {/* Image 2 */}
        <img
          src={data?.image[1]}
          alt="events-img-2"
          className="eventsgallerycard2-img-2"
        />
        <img
          src={data?.image[1]}
          alt="events-img-2"
          className="eventsgallerycard2-img-2 eventsgallerycard2-img-2-duplicate"
        />
        {/* Image 3 */}
        <img
          src={data?.image[2]}
          alt="events-img-2"
          className="eventsgallerycard2-img-3"
        />
        <img
          src={data?.image[2]}
          alt="events-img-2"
          className="eventsgallerycard2-img-3 eventsgallerycard2-img-3-duplicate"
        />
        {/* Image 4 */}
        <img
          src={data?.image[3]}
          alt="events-img-2"
          className="eventsgallerycard2-img-4"
        />
        <img
          src={data?.image[3]}
          alt="events-img-2"
          className="eventsgallerycard2-img-4 eventsgallerycard2-img-4-duplicate"
        />
      </div>
    </div>
  );
};

export default EventGalleryCard2;

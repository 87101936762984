import "./SearchbarDashboard.css";
import "./DashboardManagementHeader.css";
import { Link } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { useState } from "react";
import { IoMdSearch } from "react-icons/io";
const DashboardManagementHeader = ({
  children,
  buttonHeading,
  searchFunction,
  resetFilter,
  reduxSearchTerm,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const getSearchResults = () => {
    if (searchTerm.trim().length !== 0) {
      if (searchFunction) {
        searchFunction(searchTerm);
      } else {
        alert("Search function not found");
      }
    }
  };

  const resetContent = () => {
    setSearchTerm("");
    resetFilter();
  };
  // Function to handle the key down event
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getSearchResults();
    }
  };
  return (
    <div>
      <div className="db-mgmt-header-search-add">
        {/* Search Field */}
        <div className="searchbar-dashboard-container">
          <input
            type="text"
            className="searchbar-dashboard-field"
            onKeyDown={handleKeyDown}
            placeholder="Search here..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          ></input>
          <IoMdSearch
            className="searchbar-dashboard-icon"
            onClick={getSearchResults}
          />
        </div>
        {/* Button Field */}
        <Link to="add" className="db-mgmt-header-button">
          <FaPlus />
          <span className="db-mgmt-header-button-name">{buttonHeading}</span>
        </Link>
      </div>
      {reduxSearchTerm && (
        <div className="dashboard-management-header-clear-body">
          <FaFilter className="dashboard-search-filter-icon" />
          <span>{reduxSearchTerm ? reduxSearchTerm : null}</span>
          <button
            type="button"
            className="dashboard-management-header-clear-button"
            onClick={resetContent}
          >
            <span>Clear</span>
          </button>
        </div>
      )}
      <div className="db-mgmt-header-table-container">{children}</div>
    </div>
  );
};

export default DashboardManagementHeader;

import "./Login.css";
import logo from "../../assets/general/logo.png";
import CustomText from "../../components/FormComponents/Text/CustomText";
import { MdLogin } from "react-icons/md";
import useHandleFormData, {
  performValidation,
} from "../../hooks/useHandleFormData";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/actions/loaderActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SpinnerNew from "../../components/SpinnerNew/SpinnerNew";
const Login = () => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "email",
        validations: ["required", "email"],
        label: "Email",
      },
      {
        name: "password",
        validations: ["required"],
        label: "Password",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      let bodyData = {
        email: formData.email,
        password: formData.password,
      };
      try {
        setLoading(true);
        axios
          .post("/api/login", bodyData)
          .then((res) => {
            toast("Login Successful", "successToast");
            setFormData({
              email: "",
              password: "",
            });
            console.log(res);
            
            localStorage.setItem("token", res.data.token);
            navigate("/dashboard");
            setLoading(false);
          })
          .catch((err) => {
            toast("Error in Login", "errorToast");
            setLoading(false);
            console.log(err);

          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        setLoading(false);
      }
    }
  };
  return (
    <div className="login-page-container">
      <SpinnerNew loading={loading}></SpinnerNew>
      <div className="login-element-container">
        <div className="login-image-contaioner">
          <img className="login-logo" src={logo} alt={"logo"} />
        </div>
        <div className="login-page-heading">Log in to your account</div>
        <div className="login-form-container">
          <CustomText
            flex={"100%"}
            label={"Email ID"}
            placeholder={"Email"}
            onChange={(e) => {
              handleChange(e, "email");
            }}
            error={errors?.email}
            value={formData.email}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
            labelStyle={{ color: "#E9E9E9", fontSize: "var(--font-16-px)" }}
          ></CustomText>
          <CustomText
            flex={"100%"}
            label={"Password"}
            placeholder={"Password"}
            type={"password"}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            error={errors?.password}
            value={formData.password}
            labelStyle={{
              color: "#E9E9E9",
              fontSize: "var(--font-16-px)",
            }}
            fieldStyle={{
              background: "transparent",
              border: "1px solid rgb(85 85 85)",
              color: "white",
            }}
          ></CustomText>
          <button
            onClick={handleSubmit}
            type="button"
            className="login-button"
            disabled={loading}
          >
            {loading ? (
              <div className="button-spinner-container-new">
                <div className="button-spinner-new"></div>
              </div>
            ) : (
              <>
                <span>Sign in</span> <MdLogin />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;

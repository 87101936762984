import Image1 from "../../../../assets/simpleLayout/blogDescription/1.jpeg";
import "./BlogDescription.css";
import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import { AllBlogsData } from "../allBlogs/AllBlogsData";
import BlogPreview from "../../../../components/BlogPreview/BlogPreview";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  formatDate,
  isWithinSevenDays,
} from "../../../../functions/DateFunctions";
import { Link } from "react-router-dom";
import SpinnerInside from "../../../../components/SpinnerInside/SpinnerInside";
const BlogDescription = ({ id }) => {
  const [blogData, setBlogData] = useState({});
  const [moreBlogs, setMoreBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get("/api/blogs/" + id)
        .then((res) => {
          setBlogData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);
  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get("/api/blogs/", {
          params: {
            ignore: id,
          },
        })
        .then((res) => {
          setMoreBlogs(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [id]);
  return (
    // <SpinnerInside loading={loading}>
    <div className="blog-description-main-container">
      <div className="blog-desc-polygon-1">
        <Polygon
          marginLeft={"520px"}
          marginTop={"-250px"}
          width={300}
          height={300}
        />
      </div>
      <Polygon
        marginLeft={"-220px"}
        marginTop={"-10px"}
        width={600}
        height={600}
      />
      <CurvedLines width={2000} height={400} top={"0px"} left={"0px"} key="1" />
      <div className="blog-description-circle-1"></div>
      {/* <div className="blog-description-circle-2"></div> */}
      <div className="blog-description-circle-3"></div>
      <div className="blog-description-circle-4"></div>
      <SpinnerInside loading={loading}>
        <section id="blog-description-page">
          <div className="blogs-description-header">
            <div>
              <Link style={{ textDecoration: "none" }} to="/blogs">
                <span className="blogs-description-title">BLOGS</span>
              </Link>
            </div>
          </div>
          <div className="blogs-description-body">
            <div className="blogs-description-body-image-container">
              <img
                src={blogData?.coverImage}
                alt="blog-pic"
                className="blogs-description-body-image"
              />
            </div>
            <div className="blogs-description-body-heading-container">
              <div className="blogs-description-body-heading">
                {blogData?.topic}
              </div>
              <div className="blogs-description-body-date">
                Posted on{" "}
                {blogData?.createdAt ? formatDate(blogData?.createdAt) : null}
              </div>
            </div>
            <div className="blogs-description-body-description">
              {Array.isArray(blogData.content)
                ? blogData.content.map((content, index) => {
                    if (content.type === "heading") {
                      return (
                        <div key={index} className="blog-view-heading">
                          {content.value}
                        </div>
                      );
                    } else if (content.type === "para") {
                      return (
                        <div key={index} className="blog-view-para">
                          {content.value}
                        </div>
                      );
                    } else if (content.type === "image") {
                      return (
                        <div key={index} className="blog-view-image-container">
                          <img
                            src={content.value}
                            className="blog-view-image"
                            alt="blog-img"
                          />
                          <div className="blog-view-img-src">
                            {content.imagesource}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </div>
            <div className="blogs-description-body-tags">
              <div className="blogs-description-body-tags-container">
                {Array.isArray(blogData?.tags)
                  ? blogData?.tags.map((tag, index) => {
                      return (
                        <div
                          key={index}
                          className="blog-description-used-topic"
                        >
                          {tag}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="blogs-description-more-blogs">
            <div className="blogs-description-more-blogs-heading">
              <div className="services-page-sub-heading-set">
                {/* <span className="service-page-heading-underline"></span> */}
                <span className="services-our-services-sub-heading">Blogs</span>
                {/* <span className="service-page-heading-underline"></span> */}
              </div>
              <div className="blogs-description-more-blogs-main-heading">
                More News Like This
              </div>
            </div>
            <div className="blogs-description-more-blogs-item">
              {Array.isArray(moreBlogs)
                ? moreBlogs.slice(0, 3).map((blog, index) => {
                    return (
                      <BlogPreview
                        key={index}
                        isNew={isWithinSevenDays(blog.createdAt) ? true : false}
                        heading={blog.topic}
                        readMoreLink={"/blogs/" + blog._id}
                        viewCount={formatDate(blog.createdAt)}
                        image={blog.coverImage}
                      ></BlogPreview>
                    );
                  })
                : null}
            </div>
          </div>
        </section>
      </SpinnerInside>
    </div>
    // </SpinnerInside>
  );
};

export default BlogDescription;

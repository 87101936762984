import About from "../../containers/simpleLayout/about/About";
import AboutPeople from "../../containers/simpleLayout/about/people/AboutPeople";

const AboutPage = () => {
  return (
    <>
      <About />
      <AboutPeople />
    </>
  );
};

export default AboutPage;

import LandingImage from "../../../../assets/simpleLayout/portfolio/landing.png";
import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import "./PortfolioLanding.css";
const PortfolioLanding = () => {
  return (
    <section id="portfolio-landing">
      <CurvedLines width={2000} height={400} top={"0px"} left={"0px"} key="1" />
      <Polygon
        marginLeft={"-180px"}
        marginTop={"100px"}
        width={600}
        height={600}
      />
      <div className="portfolio-landing-container">
        <div className="portfolio-landing-heading-set">
          <span className="portfolio-landing-text-dark">
            The Work <br></br>we have<br></br>
          </span>
          <span className="portfolio-landing-text-color">Done</span>
          <div className="portfolio-landing-description">
            Unlocking Possibilities, One code at a time
          </div>
        </div>

        <img
          src={LandingImage}
          alt="portfolio landing"
          className="portfolio-landing-image"
        ></img>
      </div>
    </section>
  );
};
export default PortfolioLanding;

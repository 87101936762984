import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import "./DashboardLayout.css";
import { Outlet } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
export default function DashboardLayout() {
  return (
    <div>
      <Sidebar />
      <header className="dashboard-background">
        <Header />
      </header>
      <div className="dashboard-container">
        <main className="dashboard-container-main">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export const editButton = (
  <span style={{ color: "#23758E", cursor: "pointer" }}>
    <FaEdit />
  </span>
);
export const deleteButton = (
  <span style={{ color: "#B91C1C", cursor: "pointer" }}>
    <FaRegTrashAlt />
  </span>
);

export const viewButton = (
  <span style={{ color: "rgb(30 93 38)", cursor: "pointer" }}>
    <MdOpenInNew />
  </span>
);

import { useState } from "react";
import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import { TrimToNumOfCharacters } from "../../../../functions/TextFunctions";
import "./Testimonial.css";
import { TestimonialData } from "./TestimonialData";
import TestimonialSlider from "./TestimonialSlider/TestimonialSlider";

const Testimonial = () => {
  const [testimonialData, getTestimonialData] = useState([]);
  return (
    <>
      <div style={{ position: "relative" }}>
        <CurvedLines
          width={2000}
          height={900}
          top={"-250px"}
          left={"50px"}
          cliptop={"50px"}
          key="3"
          color={"#FCFCFC"}
        />
      </div>
      <section className={"home-top-space"} id="home-testimonial">
        <Polygon
          marginLeft={"-220px"}
          marginTop={"-280px"}
          width={400}
          height={400}
          className="polygon-testimonial-1"
        />
        <div className="polygon2-testimonial">
          <Polygon
            width={600}
            height={600}
            marginLeft={"auto"}
            marginRight={"-800px"}
            className="polygon-testimonial-2"
          />
        </div>
        <div className="testimonial-container">
          <TestimonialSlider getTestimonialData={getTestimonialData} />
        </div>
        <div className="testimonial-container-mobile">
          <div className="testimonial-container-mobile-heading-1">
            Clients Feedback
          </div>
          <div className="testimonial-container-mobile-main-heading">
            <span className="testimonial-container-mobile-main-heading-dark">
              Some
            </span>
            <span className="testimonial-container-mobile-main-heading-color">
              {" "}
              Great
            </span>
            <br></br>
            <span className="testimonial-container-mobile-main-heading-color">
              Words
            </span>
            <span className="testimonial-container-mobile-main-heading-dark">
              {" "}
              From Our
            </span>
            <br></br>
            <span className="testimonial-container-mobile-main-heading-dark">
              Clients
            </span>
          </div>
          <div className="testimonial-cards-mobile">
            {testimonialData.map((testimonial, index) => {
              return (
                <div
                  className={"testimonial-mobile-card "}
                  style={{
                    background: "white",
                  }}
                >
                  <div className="testimonial-mobile-card-desc">
                    {TrimToNumOfCharacters(180, testimonial.description)}
                  </div>
                  <div className="testimonial-mobile-card-iamge-and-name">
                    <img
                      src={testimonial.image}
                      className="testimonial-mobile-card-iamge"
                      alt="person"
                    ></img>
                    <div className="testimonial-mobile-card-name-position-container">
                      <div className="testimonial-mobile-card-name">
                        {testimonial.name}
                      </div>
                      <div className="testimonial-mobile-card-position">
                        {testimonial.position + " at " + testimonial.company}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

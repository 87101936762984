import React from "react";
import "./Footer.css";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
import ContactIcon from "../../../assets/simpleLayout/footer/contactIcon.svg";
import LocationIcon from "../../../assets/simpleLayout/footer/locationIcon.svg";
import MailIcon from "../../../assets/simpleLayout/footer/mailIcon.svg";
const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        {/* Footer Main Details */}
        <div className="footer-mail-details">
          <div className="footer-about-company">
            <div className="footer-company-name">NilaviTech</div>
            <div className="footer-company-description">
              To provide innovative and reliable software solutions that empower
              businesses to thrive in the digital age.
            </div>
          </div>
          <div className="footer-services">
            <div className="footer-details-heading">Services</div>
            <div className="footer-details-menu">
              <div>Ui/Ux Design</div>
              <div>FrontEnd Deverlop</div>
              <div>Wordpress Building</div>
              <div>Auto bot</div>
            </div>
          </div>
          <div className="footer-contact">
            <div className="footer-details-heading">Contact</div>
            <div className="footer-details-menu">
              <div className="footer-contact-container">
                <img src={ContactIcon} alt="contact"></img>
                <span>99 88 99 66 99</span>
              </div>
              <div className="footer-contact-container">
                <img src={MailIcon} alt="mail"></img>
                <span>NilaviTech.com</span>
              </div>
              <div className="footer-contact-container">
                <img src={LocationIcon} alt="address"></img>
                <span className="footer-address">
                  No 140, Darvin Road, Bathramulla, Colombo.
                </span>
                <span className="footer-address-mobile">
                  Bathramulla, Colombo.
                </span>
              </div>
            </div>
          </div>
          <div className="footer-info-mobile">
            <span className="footer-info-mobile-copyright">© 2024 NilaviTech</span>
            <span className="footer-info-mobile-rights">All Rights Reserved</span>
          </div>
        </div>
        {/* Footer Social Media */}
        <div className="footer-social-media">
          <FaLinkedinIn />
          <FaFacebookF />
          <FaYoutube />
          <RiInstagramFill />
          <FaTwitter />
        </div>
      </div>
      <div className="footer-container-2">
        <div className="footer-copyright">© NilaviTech 2024</div>
        <div className="footer-copyright-mobile">© NilaviTech 2024</div>
      </div>
    </footer>
  );
};

export default Footer;

import DashboardManagementHeader from "../../../../components/DashboardManagementHeader/DashboardManagementHeader";
import Table from "../../../../components/Table/Table";
import { headerData, caption } from "./TableConfig";
import "./AllTeams.css";
import {
  deleteButton,
  editButton,
} from "../../../../layouts/dashboardLayout/DashboardLayout";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { showToastMessage } from "../../../../redux/actions/toastActions";
import { Link } from "react-router-dom";
import PopUp from "../../../../components/PopUp/PopUp";
import { formatDate } from "../../../../functions/DateFunctions";
import Pagination from "../../../../components/Pagination/Pagination";
import ExceptionComponent from "../../../../components/ExceptionComponent/ExceptionComponent";

const AllTeams = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  // Related to Pagination
  const limit = { search: 5, fetch: 5 };
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (page) => setCurrentPage(page);
  // Related to search
  const [mode, setMode] = useState("get");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (mode === "get") {
      dispatch(startLoading());
      axios
        .get("/api/teams", {
          params: {
            limit: limit.fetch,
            page: currentPage,
          },
        })
        .then((res) => {
          dispatch(stopLoading());
          setData(res.data);
        })
        .catch((err) => {
          dispatch(stopLoading());
          console.log(err);
          setErrorApi(true);
        });
    }
    if (mode === "search") {
      dispatch(startLoading());
      axios
        .get("/api/teams/search", {
          params: {
            searchTerm: searchTerm,
            limit: limit.search,
            page: currentPage,
          },
        })
        .then((res) => {
          dispatch(stopLoading());
          setData(res.data);
        })
        .catch((err) => {
          dispatch(stopLoading());
          console.log(err);
          setErrorApi(true);
        });
    }
  }, [currentPage, mode, refresh, searchTerm]);

  const onDelete = async (id) => {
    try {
      axios
        .delete(`/api/teams/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setRefresh(!refresh);
          setCurrentPage(1);
          dispatch(
            showToastMessage("Employee Deleted Successfully", "successToast")
          );
        })
        .catch((err) => {
          dispatch(showToastMessage("Employee Deletion Failed", "errorToast"));
        });
    } catch (error) {
      dispatch(showToastMessage("Something went wrong", "errorToast"));
    }
  };

  const tableData = Array.isArray(data?.data)
    ? data?.data.map((data, index) => {
        return {
          tableViewInfo: [
            <img
              src={data.image}
              alt="user"
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            ></img>,
            data.firstName + " " + data.lastName,
            data.role,
            formatDate(data.dateOfPlacement),
            <div className="all-services-action-icons">
              <Link to={`edit/${data._id}`}>{editButton}</Link>
              <PopUp
                message="Do you want to delete this employee ?"
                onYes={[
                  () => {
                    onDelete(data._id);
                  },
                ]}
                buttonText={deleteButton}
                className="dashboard-form-delete-button"
              />
            </div>,
          ],
          onClickTr: ``,
          id: index,
        };
      })
    : [];

  return (
    <DashboardManagementHeader
      // trigger search mode and save search term
      searchFunction={(searchTermDMH) => {
        setMode("search");
        setCurrentPage(1);
        setSearchTerm(searchTermDMH);
      }}
      // clear search term and reset to get mode
      resetFilter={() => {
        setCurrentPage(1);
        setMode("get");
        setSearchTerm("");
      }}
      reduxSearchTerm={searchTerm}
      buttonHeading={
        <span>
          <span className="db-add-hide-on-mobile">New</span> Employee
        </span>
      }
    >
      {Array.isArray(data?.data) ? (
        data?.data.length !== 0 ? (
          <>
            <Table
              caption={caption}
              headerData={headerData}
              tableData={tableData}
              disableRowClick={true}
            />
            <Pagination
              currentPage={currentPage}
              totalPage={data?.totalPages}
              paginate={paginate}
            />
          </>
        ) : (
          <ExceptionComponent type="noresult" />
        )
      ) : errorApi ? (
        <ExceptionComponent type="error" />
      ) : null}
    </DashboardManagementHeader>
  );
};

export default AllTeams;

import LandingImage from "../../../assets/simpleLayout/career/CareerLanding.png";
import "./CareerLanding.css";
import Polygon from "../../../components/Polygon/Polygon";
import CurvedLines from "../../../components/SimpleLayout/CurvedLines/CurvedLines";
const CareerLanding = () => {
  return (
    <div className="careers-page-landing-container">
      <Polygon
        marginLeft={"-190px"}
        marginTop={"0px"}
        width={500}
        height={500}
      />
      <CurvedLines width={2000} height={400} top={"0px"} left={"0px"} key="1" />

      <div className="careers-page-landing-title">
        <span className="career-page-landing-line-dark">Choose Us</span>
        <span className="career-page-landing-line-dark career-landing-colon">
          :{" "}
        </span>
        <br className="career-page-break-1"></br>
        <span className="career-page-landing-line-dark">Your Path to </span>
        <br className="career-page-break"></br>
        <span className="career-page-landing-line-color">Innovation </span>
        <span className="career-page-landing-line-dark"> & </span>
        <br className="career-page-break-2"></br>
        <span className="career-page-landing-line-color">Success</span>
        <div className="career-landing-mobile-view-image">
          <img
            src={LandingImage}
            alt="career-landing-pic"
            className="careers-page-landing-pic"
          ></img>
        </div>
      </div>

      <div className="careers-page-landing-pic-container">
        <img
          src={LandingImage}
          alt="career-landing-pic"
          className="careers-page-landing-pic"
        ></img>
      </div>
    </div>
  );
};
export default CareerLanding;

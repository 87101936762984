import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import "./PortfolioBrief.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../functions/DateFunctions";
import ExceptionComponent from "../../../../components/ExceptionComponent/ExceptionComponent";
import PlayStoreIcon from "./icons/PlayStore.svg";
import WebIcon from "./icons/Web.svg";
import AppleIcon from "./icons/Apple.svg";
import SpinnerInside from "../../../../components/SpinnerInside/SpinnerInside";
const PortfolioBrief = ({ projectId }) => {
  const [projectData, setProjectData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      setLoading(true);
      axios
        .get("/api/projects/" + projectId)
        .then((res) => {
          setProjectData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  }, []);
  return (
    <div className="blog-description-main-container">
      <div className="blog-desc-polygon-1">
        <Polygon
          marginLeft={"520px"}
          marginTop={"-250px"}
          width={300}
          height={300}
        />
      </div>
      <Polygon
        marginLeft={"-220px"}
        marginTop={"-10px"}
        width={600}
        height={600}
      />
      <CurvedLines width={2000} height={400} top={"0px"} left={"0px"} key="1" />
      <div className="blog-description-circle-1"></div>
      {/* <div className="portfolio-description-circle-2"></div> */}
      <div className="blog-description-circle-3"></div>
      <div className="blog-description-circle-4"></div>
      <SpinnerInside loading={loading}>
        <section id="blog-description-page">
          <div className="blogs-description-header">
            <div>
              <Link style={{ textDecoration: "none" }} to="/portfolio">
                <span className="blogs-description-title">Projects</span>
              </Link>
            </div>
          </div>
          {error ? (
            <ExceptionComponent type="error"></ExceptionComponent>
          ) : (
            <div className="blogs-description-body">
              <div className="blogs-description-body-image-container">
                <img
                  src={projectData?.image}
                  alt="blog-pic"
                  className="blogs-description-body-image"
                />
              </div>
              <div className="blogs-description-body-heading-container">
                <div className="blogs-description-body-heading">
                  {projectData?.name}
                </div>
                <div className="blogs-description-body-date">
                  Posted on{" "}
                  {projectData?.createdAt
                    ? formatDate(projectData?.createdAt)
                    : null}
                </div>
              </div>
              <div className="blogs-description-body-description">
                {projectData?.description
                  ? projectData.description
                      .split("\r\n")
                      .map((singleReq, index) => {
                        return (
                          <div key={index} className="blog-view-para">
                            {singleReq}
                          </div>
                        );
                      })
                  : null}
              </div>
              <div className="portfolio-description-body-icons">
                {projectData?.appstore && (
                  <Link
                    target="_blank"
                    to={projectData?.appstore}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <div className="portfolio-description-body-icon-container">
                      <img
                        src={AppleIcon}
                        className="body-desc-icon"
                        alt="social media"
                      ></img>
                      <div className="portfolio-description-body-icon-desc">
                        <div className="portfolio-desc-icon-p1">
                          Download on the
                        </div>
                        <div className="portfolio-desc-icon-p2">App Store</div>
                      </div>
                    </div>
                  </Link>
                )}
                {projectData?.playstore && (
                  <Link
                    target="_blank"
                    to={projectData?.playstore}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <div className="portfolio-description-body-icon-container">
                      <img
                        src={PlayStoreIcon}
                        className="body-desc-icon"
                        alt="social media"
                      ></img>
                      <div className="portfolio-description-body-icon-desc">
                        <div className="portfolio-desc-icon-p1">
                          Download on the
                        </div>
                        <div className="portfolio-desc-icon-p2">App Store</div>
                      </div>
                    </div>
                  </Link>
                )}

                {projectData?.website && (
                  <Link
                    target="_blank"
                    to={projectData?.website}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <div className="portfolio-description-body-icon-container">
                      <img
                        src={WebIcon}
                        className="body-desc-icon"
                        alt="social media"
                      ></img>
                      <div className="portfolio-description-body-icon-desc">
                        <div className="portfolio-desc-icon-p1">View on</div>
                        <div className="portfolio-desc-icon-p2">Web</div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          )}
        </section>
      </SpinnerInside>
    </div>
  );
};

export default PortfolioBrief;

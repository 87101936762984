import { useEffect, useState } from "react";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useDispatch } from "react-redux";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ISO_to_HTML_Format } from "../../../../functions/DateFunctions";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
const EditEvents = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  useEffect(() => {
    dispatch(startLoading());
    axios
      .get("/api/events/" + id)
      .then((res) => {
        dispatch(stopLoading());
        setFormData({
          event: res.data.name,
          description: res.data.description,
          date: ISO_to_HTML_Format(res.data.date),
          image: res.data.image,
          venue: res.data.venue,
        });
      })
      .catch((error) => {
        dispatch(stopLoading());
        toast("Error in Fetching Service", "errorToast");
      });
  }, []);
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 100,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "event",
        validations: ["required"],
        label: "Event",
      },
      {
        name: "date",
        validations: ["required"],
        label: "Date",
      },
      {
        name: "venue",
        validations: ["required"],
        label: "Venue",
      },
      {
        name: "description",
        validations: ["required"],
        label: "Description",
      },
    ];
    console.log(formData);
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("name", formData.event);
      bodyData.append("date", formData.date);
      bodyData.append("venue", formData.venue);
      bodyData.append("description", formData.description);
      formData.image.forEach((file) => {
        bodyData.append(`image`, file);
      });
      try {
        dispatch(startLoading());
        axios
          .put("/api/events/" + id, bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Event Updated Successfully", "successToast");
            setFormData({
              event: "",
              date: "",
              venue: "",
              image: [],
              description: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            navigate("/dashboard/events");
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Updating Event", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };
  return (
    <>
      <DashboardAddEdit
        heading={"Add Event"}
        parentLink="/dashboard/events"
        parentName={"Events"}
      >
        <div className="add-events-body">
          <div className="add-events-main-container">
            <div className="add-events-main-container-left">
              <CustomText
                label={"Event *"}
                flex={"100%"}
                onChange={(e) => {
                  handleChange(e, "event");
                }}
                error={errors?.event}
                value={formData.event}
                placeholder={"Event Name"}
              ></CustomText>
              <CustomText
                labelClassName={"add-events-date-venue"}
                label={"Date *"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "date");
                }}
                error={errors?.date}
                value={formData.date}
                type={"date"}
              ></CustomText>
              <CustomText
                label={"Venue"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "venue");
                }}
                error={errors?.venue}
                value={formData.venue}
                placeholder={"Venue"}
              ></CustomText>
              {/* <div style={{ flex: "100%" }}>
                <label
                  for="add-events-file"
                  className="add-events-file-upload-label"
                >
                  <span type="button" class="add-events-browse-button">
                    <MdOutlineCloudUpload />
                    Browse file
                  </span>
                  <input
                    // value={formData.image}
                    id="add-events-file"
                    type="file"
                    multiple={true}
                    onChange={(e) => {
                      handleChange(e.target.files, "image");
                    }}
                  />
                </label>
                <span className="form-error-message">{errors?.image}</span>
              </div> */}
            </div>
            <div className="add-events-main-container-left">
              <CustomTextArea
                label={"Description *"}
                rows={7}
                flex={"100%"}
                onChange={(e) => {
                  handleChange(e, "description");
                }}
                error={errors?.description}
                value={formData.description}
                placeholder={"Description"}
              ></CustomTextArea>
              {/* <div>
                {formData.image && Object.keys(formData.image).length !== 0 ? (
                  <label
                    class="custom-text-label undefined"
                    style={{ marginBottom: "6px" }}
                  >
                    Upload Success
                  </label>
                ) : null}
                <div className="add-event-image-name-container">
                  {formData.image &&
                    Object.keys(formData.image).map((key) => {
                      return (
                        <div className="add-event-image-file-name">
                          {formData.image[key]?.name}
                        </div>
                      );
                    })}
                </div>
              </div> */}
            </div>
          </div>
          <CustomSingleImageUpload
            urlArray={formData.image}
            onChange={(value) => {
              handleChange(value, "image");
            }}
            label={"Photo *"}
            dropZoneSizeClassName={"add-events-dropzone-size"}
            error={errors?.image}
            status={clearDragAndDrop}
          ></CustomSingleImageUpload>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/events">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default EditEvents;

import AllEvents from "../../../containers/simpleLayout/events/allEvents/AllEvents";
import AllEventsGallery from "../../../containers/simpleLayout/events/allEvents/AllEventsGallery";

const EventsPage = () => {
  return (
    <>
      <AllEvents />
      <AllEventsGallery />
    </>
  );
};

export default EventsPage;

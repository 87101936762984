import "./HoverAnimate.css";
const HoverAnimate = ({ image, topic, count }) => {
  return (
    <div className="hover-animate-container">
      <div className="hover-animate-image-container">
        <img
          src={image}
          alt="1"
          className="hover-animate-image"
          draggable={false}
        />
        <div className="hover-animate-text-set">
          <div className="hover-animate-text">
            {topic}
            <br></br>Topic
          </div>
          {count !== 0 && (
            <div className="hover-animate-topic-count">
              {count}
              <br></br>
              <span className="hover-animate-topic-text">Topics</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default HoverAnimate;

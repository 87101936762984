import { useDispatch } from "react-redux";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useEffect, useState } from "react";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { ISO_to_HTML_Format } from "../../../../functions/DateFunctions";
import { Link, useNavigate } from "react-router-dom";

const EditTeams = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  useEffect(() => {
    dispatch(startLoading());
    axios
      .get("/api/teams/" + id)
      .then((res) => {
        dispatch(stopLoading());
        setFormData({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          dateOfPlacement: ISO_to_HTML_Format(res.data.dateOfPlacement),
          role: res.data.role,
          instagram: res.data.instagram || "",
          facebook: res.data.facebook || "",
          linkedin: res.data.linkedin || "",
          image: [res.data.image],
        });
      })
      .catch((error) => {
        dispatch(stopLoading());
        toast("Error in Fetching Service", "errorToast");
      });
  }, []);
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "firstName",
        validations: ["required"],
        label: "First Name",
      },
      {
        name: "lastName",
        validations: ["required"],
        label: "Last Name",
      },
      {
        name: "dateOfPlacement",
        validations: ["required"],
        label: "Date Of Placement",
      },
      {
        name: "role",
        validations: ["required"],
        label: "Role",
      },
      {
        name: "instagram",
        validations: ["url"],
        label: "Instagram",
      },
      {
        name: "facebook",
        validations: ["url"],
        label: "Facebook",
      },
      {
        name: "linkedin",
        validations: ["url"],
        label: "LinkednIn",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    // delete facebook from validation results
    if (formData.facebook === undefined || formData.facebook === "") {
      delete validationResults.facebook;
    }
    if (formData.linkedin === undefined || formData.linkedin === "") {
      delete validationResults.linkedin;
    }
    if (formData.instagram === undefined || formData.instagram === "") {
      delete validationResults.instagram;
    }
    handleErrors(validationResults);
    console.log(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("firstName", formData.firstName);
      bodyData.append("lastName", formData.lastName);
      bodyData.append("role", formData.role);
      bodyData.append("dateOfPlacement", formData.dateOfPlacement);
      bodyData.append("instagram", formData.instagram);
      bodyData.append("facebook", formData.facebook);
      bodyData.append("linkedin", formData.linkedin);
      bodyData.append("image", formData.image[0]);

      try {
        dispatch(startLoading());
        axios
          .put("/api/teams/" + id, bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Employee Updated Successfully", "successToast");
            setFormData({
              firstName: "",
              image: [],
              lastName: "",
              role: "",
              dateOfPlacement: "",
              instagram: "",
              facebook: "",
              linkedin: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            navigate("/dashboard/teams");
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Updating Employee", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };

  return (
    <>
      <DashboardAddEdit
        heading={"Edit Employee"}
        parentLink="/dashboard/teams"
        parentName={"Teams"}
      >
        <div className="add-services-body">
          <div className="add-service-body-col-1">
            <CustomSingleImageUpload
              urlArray={formData.image}
              onChange={(value) => {
                handleChange(value, "image");
              }}
              label={"Photo *"}
              dropZoneSizeClassName={"add-service-dropzone-size"}
              error={errors?.image}
              status={clearDragAndDrop}
            ></CustomSingleImageUpload>
          </div>
          <div className="add-service-body-col-2">
            <CustomText
              label={"First Name *"}
              flex={"1"}
              placeholder={"First Name"}
              onChange={(e) => {
                handleChange(e, "firstName");
              }}
              error={errors?.firstName}
              value={formData.firstName}
            ></CustomText>
            <CustomText
              label={"Last Name *"}
              flex={"1"}
              placeholder={"Last Name"}
              onChange={(e) => {
                handleChange(e, "lastName");
              }}
              error={errors?.lastName}
              value={formData.lastName}
            ></CustomText>
            <div style={{ flex: "100%" }}></div>
            <CustomText
              label={"Date of Placement *"}
              flex={"1"}
              type={"date"}
              onChange={(e) => {
                handleChange(e, "dateOfPlacement");
              }}
              error={errors?.dateOfPlacement}
              value={formData.dateOfPlacement}
            ></CustomText>
            <CustomText
              label={<div className="emtpty-field-db-form">Role *</div>}
              flex={"1"}
              placeholder={"Role"}
              onChange={(e) => {
                handleChange(e, "role");
              }}
              error={errors?.role}
              value={formData.role}
            ></CustomText>
            <CustomText
              label={"Instagram"}
              flex={"2"}
              placeholder={"https://www.example.com"}
              onChange={(e) => {
                handleChange(e, "instagram");
              }}
              error={errors?.instagram}
              value={formData.instagram}
            ></CustomText>
            <div style={{ flex: "100%" }}></div>
            <CustomText
              label={"Facebook"}
              flex={"2"}
              placeholder={"https://www.example.com"}
              onChange={(e) => {
                handleChange(e, "facebook");
              }}
              error={errors?.facebook}
              value={formData.facebook}
            ></CustomText>
            <CustomText
              label={"LinkedIn"}
              flex={"2"}
              placeholder={"https://www.example.com"}
              onChange={(e) => {
                handleChange(e, "linkedin");
              }}
              error={errors?.linkedin}
              value={formData.linkedin}
            ></CustomText>
          </div>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/teams">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default EditTeams;

import { useDispatch } from "react-redux";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useEffect, useState } from "react";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const EditReviews = ({ id }) => {
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  useEffect(() => {
    dispatch(startLoading());
    axios
      .get("/api/reviews/" + id)
      .then((res) => {
        dispatch(stopLoading());
        setFormData({
          name: res.data.name,
          description: res.data.description,
          position: res.data.position,
          company: res.data.company,
          image: [res.data.image],
        });
      })
      .catch((error) => {
        dispatch(stopLoading());
        toast("Error in Fetching Service", "errorToast");
      });
  }, []);
  const navigate = useNavigate();
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "name",
        validations: ["required"],
        label: "Name",
      },
      {
        name: "description",
        validations: ["required"],
        label: "Description",
      },
      {
        name: "position",
        validations: ["required"],
        label: "Position",
      },
      {
        name: "company",
        validations: ["required"],
        label: "Company",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("name", formData.name);
      bodyData.append("description", formData.description);
      bodyData.append("position", formData.position);
      bodyData.append("company", formData.company);
      bodyData.append("image", formData.image[0]);

      try {
        dispatch(startLoading());
        axios
          .put("/api/reviews/" + id, bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Review Updated Successfully", "successToast");
            setFormData({
              name: "",
              image: [],
              description: "",
              date: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            dispatch(stopLoading());
            navigate("/dashboard/reviews");
          })
          .catch((err) => {
            toast("Error in Updating Review", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };
  return (
    <>
      <DashboardAddEdit
        heading={"Edit Review"}
        parentLink="/dashboard/reviews"
        parentName={"Reviews"}
      >
        <div className="add-services-body">
          <div className="add-service-body-col-1">
            <CustomSingleImageUpload
              // value={formData.image}
              urlArray={formData.image}
              onChange={(value) => {
                handleChange(value, "image");
              }}
              label={"Photo *"}
              dropZoneSizeClassName={"add-service-dropzone-size"}
              error={errors?.image}
              status={clearDragAndDrop}
            ></CustomSingleImageUpload>
          </div>
          <div className="add-service-body-col-2">
            <CustomText
              label={"Name *"}
              flex={"1"}
              placeholder={"Reviewer Name"}
              onChange={(e) => {
                handleChange(e, "name");
              }}
              error={errors?.name}
              value={formData.name}
            ></CustomText>
            <CustomText
              label={"Position *"}
              flex={"1"}
              placeholder={"Reviewer Position"}
              onChange={(e) => {
                handleChange(e, "position");
              }}
              error={errors?.position}
              value={formData.position}
            ></CustomText>
            <div className="add-review-col-row-2">
              <CustomTextArea
                label={"Description *"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "description");
                }}
                error={errors?.description}
                value={formData.description}
                placeholder={"Description"}
              ></CustomTextArea>
              <div
                style={{ flex: 1 }}
                className="add-review-company-order-container"
              >
                <CustomText
                  label={"Company *"}
                  flex={"100%"}
                  placeholder={"Company Name"}
                  onChange={(e) => {
                    handleChange(e, "company");
                  }}
                  error={errors?.company}
                  value={formData.company}
                ></CustomText>
              </div>
            </div>
          </div>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/reviews">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default EditReviews;

import React, { useEffect, useRef } from "react";
import "./AutoTextArea.css";

const AutoTextArea = ({
  value,
  onChange,
  className,
  style,
  placeholder,
  error,
}) => {
  const textareaRef = useRef(null);

  const autoResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  useEffect(() => {
    autoResize();
  }, [value]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener("input", autoResize, false);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener("input", autoResize, false);
      }
    };
  }, []);

  const handleTextAreaContent = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div>
      <textarea
        value={value}
        onChange={(e) => {
          handleTextAreaContent(e);
        }}
        rows={1}
        className={"autoresizing " + className}
        style={style}
        placeholder={placeholder}
        ref={textareaRef}
      ></textarea>
      {error && <span className="form-error-message">{error}</span>}
    </div>
  );
};

export default AutoTextArea;

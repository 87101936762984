import "./TestimonialView.css";
const TestimonialView = ({ personImage, name = "", position = "", review = "" }) => {
  return (
    <div className="testimonial-view-container">
      <div className="testimonial-view-person-image">
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
        <img src={personImage} alt="person" className="testimonial-person-image" />
      </div>
      <div className="testimonial-view-person-details">
        <div className="testimonial-quotes">
          <svg
            width="56"
            height="43"
            viewBox="0 0 56 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3" clip-path="url(#clip0_214_3399)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M55.7544 6.71583C47.4037 10.4469 43.2283 14.8308 43.2283 19.8677C46.7877 20.2408 49.7309 21.5622 52.0582 23.8319C54.3855 26.1016 55.5491 28.7288 55.5491 31.7137C55.5491 34.885 54.4197 37.5589 52.1609 39.7354C49.9021 41.9118 47.0615 43 43.639 43C39.8059 43 36.4862 41.5853 33.6798 38.756C30.8734 35.9266 29.4702 32.491 29.4702 28.449C29.4702 16.3231 36.931 6.84024 51.8529 0L55.7544 6.71583ZM26.2842 6.71583C17.865 10.4469 13.6555 14.8308 13.6555 19.8677C17.2833 20.2408 20.2607 21.5622 22.588 23.8319C24.9153 26.1016 26.0789 28.7288 26.0789 31.7137C26.0789 34.885 24.9324 37.5589 22.6393 39.7354C20.3463 41.9118 17.4886 43 14.0662 43C10.233 43 6.93042 41.5853 4.15825 38.756C1.38607 35.9266 0 32.491 0 28.449C0 16.3231 7.42659 6.84024 22.28 0L26.2842 6.71583Z"
                fill="#AFB300"
              />
            </g>
            <defs>
              <clipPath id="clip0_214_3399">
                <rect width="56" height="43" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="testimonial-view-person-name">{name}</div>
        <div className="testimonial-view-person-position">{position}</div>
        <div className="testimonial-view-person-review">{review}</div>
      </div>
    </div>
  );
};

export default TestimonialView;

export const SimpleLayoutNavConfig = [
  {
    name: "Service",
    link: "/services",
  },
  {
    name: "Career",
    link: "/careers",
  },
  {
    name: "About Us",
    link: "/about",
  },
  {
    name: "Blogs",
    link: "/blogs",
  },
  {
    name: "Portfolio",
    link: "/portfolio",
  },
  {
    name: "Events",
    link: "/events",
  },
];

import "./PeopleView.css";
// import personImage from "../../../../../assets/simpleLayout/about/people/person1.png";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
const PeopleView = ({
  personImage,
  name,
  position,
  facebook,
  skype,
  twitter,
  linkedin,
}) => {
  return (
    <div className="about-people-view-main-container">
      <div className="about-people-view-image">
        <img
          src={personImage}
          alt="person"
          className="about-people-avatar"
        ></img>
        <div className="about-people-social-media">
          {facebook && (
            <a
              className="about-people-one-social-media"
              href={facebook}
              target="_blank"
            >
              <FaFacebookF />
            </a>
          )}
          {linkedin && (
            <a
              className="about-people-one-social-media"
              href={linkedin}
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
          )}
          {twitter && (
            <a
              className="about-people-one-social-media"
              href={twitter}
              target="_blank"
            >
              <FaTwitter />
            </a>
          )}
          {skype && (
            <a
              className="about-people-one-social-media"
              href={skype}
              target="_blank"
            >
              <FaSkype />
            </a>
          )}
        </div>
      </div>
      <div className="about-people-view-detail">
        <div className="about-people-view-name">{name}</div>
        <div className="about-people-view-position">{position}</div>
      </div>
    </div>
  );
};
export default PeopleView;

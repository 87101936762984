import "./DashboardAddEdit.css";
import { Link } from "react-router-dom";
const DashboardAddEdit = ({ heading, children, parentLink, parentName }) => {
  return (
    <>
      {/* <div className="add-edit-navi">
        <Link to={parentLink} className="add-edit-navi-item">
          {parentName}
        </Link>
        <div className="add-edit-navi-item">{heading}</div>
      </div> */}
      <div className="add-edit-navigation">
        <Link to={parentLink}>
          <i className="fas fa-arrow-left"></i> {parentName}
        </Link>
        <span>
          {" / "}
          <span className="">{heading}</span>
        </span>
      </div>
      <div className="db-edit-delete-form-container">
        <div className="db-edit-delete-header">
          <span className="db-edit-delete-heading">{heading}</span>
        </div>
        <div className="db-edit-delete-children"> {children}</div>
      </div>
    </>
  );
};
export default DashboardAddEdit;

import "./SpinnerInside.css";
const SpinnerInside = ({ loading, children, style }) => {
  return (
    <>
      {loading && (
        <div className="spinner-container-new">
          <div className="spinner-new"></div>
        </div>
      )}
      <div
        aria-hidden={false}
        className={
          loading
            ? "spinner-inside-children"
            : "spinner-inside-children spinner-inside-children-active"
        }
      >
        {children}
      </div>
    </>
  );
};
export default SpinnerInside;

// import "./SpinnerInside.css";
// const SpinnerInside = ({ loading, children, style }) => {
//   return (
//     <>
//       {loading ? (
//         <div className="spinner-container-new">
//           <div className="spinner-new"></div>
//         </div>
//       ) : (
//         children
//       )}
//     </>
//   );
// };
// export default SpinnerInside;

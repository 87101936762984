import { useDispatch } from "react-redux";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import "./AddPortfolio.css";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useState } from "react";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { Link } from "react-router-dom";
const AddPortfolio = () => {
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "name",
        validations: ["required"],
        label: "Name",
      },
      {
        name: "description",
        validations: ["required"],
        label: "Description",
      },
      {
        name: "date",
        validations: ["required"],
        label: "Date",
      },
      {
        name: "playstore",
        validations: ["url"],
        label: "Web",
      },
      {
        name: "appstore",
        validations: ["url"],
        label: "Web",
      },
      {
        name: "website",
        validations: ["url"],
        label: "Web",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    if (formData.playstore === undefined || formData.playstore === "") {
      delete validationResults.playstore;
    }
    if (formData.appstore === undefined || formData.appstore === "") {
      delete validationResults.appstore;
    }
    if (formData.website === undefined || formData.website === "") {
      delete validationResults.website;
    }
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("name", formData.name);
      bodyData.append("description", formData.description);
      bodyData.append("date", formData.date);
      bodyData.append("image", formData.image[0]);
      bodyData.append("playstore", formData.playstore);
      bodyData.append("appstore", formData.appstore);
      bodyData.append("website", formData.website);

      try {
        dispatch(startLoading());
        axios
          .post("/api/projects", bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Project Added Successfully", "successToast");
            setFormData({
              name: "",
              image: [],
              description: "",
              date: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Adding Project", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };
  return (
    <>
      <DashboardAddEdit
        heading={"Add Project"}
        parentLink="/dashboard/portfolio"
        parentName={"Portfolios"}
      >
        <div className="add-portfolio-body">
          <div style={{ flex: "100%", display: "flex", gap: "10px" }}>
            <CustomText
              label={"Name"}
              flex={"1"}
              onChange={(e) => {
                handleChange(e, "name");
              }}
              error={errors?.name}
              value={formData.name}
              placeholder={"Name"}
            ></CustomText>
            <CustomText
              label={"Date"}
              flex={"1"}
              type={"date"}
              onChange={(e) => {
                handleChange(e, "date");
              }}
              error={errors?.date}
              value={formData.date}
            ></CustomText>
          </div>
          <CustomText
            label={"Play Store"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "playstore");
            }}
            error={errors?.playstore}
            value={formData.playstore}
            placeholder={"placeholder Link"}
          ></CustomText>
          <CustomText
            label={"App Store"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "appstore");
            }}
            error={errors?.appstore}
            value={formData.appstore}
            placeholder={"Appstore Link"}
          ></CustomText>
          <CustomText
            label={"Website"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "website");
            }}
            error={errors?.website}
            value={formData.website}
            placeholder={"Website Link"}
          ></CustomText>
          <CustomTextArea
            label={"Description *"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "description");
            }}
            error={errors?.description}
            value={formData.description}
            placeholder={"Description"}
          ></CustomTextArea>
          <CustomSingleImageUpload
            value={formData.image}
            onChange={(value) => {
              handleChange(value, "image");
            }}
            label={"Photo *"}
            dropZoneSizeClassName={"add-portfolio-dropzone-size"}
            error={errors?.image}
            status={clearDragAndDrop}
          ></CustomSingleImageUpload>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/portfolio">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};
export default AddPortfolio;

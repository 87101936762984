import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import "./AllEvents.css";
import image1 from "../../../../assets/simpleLayout/events/landing/image1.png";
import image2 from "../../../../assets/simpleLayout/events/landing/image2.png";
import image3 from "../../../../assets/simpleLayout/events/landing/image3.png";
import image4 from "../../../../assets/simpleLayout/events/landing/image4.png";
import image5 from "../../../../assets/simpleLayout/events/landing/image5.png";

const AllEvents = () => {
  return (
    <div>
      <section id="all-events-landing">
        <Polygon
          marginLeft={"-160px"}
          marginTop={"20px"}
          width={500}
          height={500}
        />
        <CurvedLines
          width={2000}
          height={400}
          top={"0px"}
          left={"0px"}
          key="1"
        />
        <div className="all-events-sl-landing-container">
          <div className="all-events-sl-landing-heading">
            <span className="all-events-sl-landing-heading-dark">
              Unleash the
            </span>
            <br></br>
            <span className="all-events-sl-landing-heading-color">Moments</span>
            <br></br>
            <span className="all-events-sl-landing-heading-dark">of Our </span>
            <span className="all-events-sl-landing-heading-color">Journey</span>
          </div>
          <div className="all-events-sl-landing-image-container">
            <img
              src={image1}
              alt="landing1"
              className="all-events-landing-img-1 all-events-sl-landing-image"
            ></img>
            <img
              src={image2}
              alt="landing1"
              className="all-events-landing-img-2 all-events-sl-landing-image"
            ></img>
            <img
              src={image3}
              alt="landing1"
              className="all-events-landing-img-3 all-events-sl-landing-image"
            ></img>
            <img
              src={image4}
              alt="landing1"
              className="all-events-landing-img-4 all-events-sl-landing-image"
            ></img>
            <img
              src={image5}
              alt="landing1"
              className="all-events-landing-img-5 all-events-sl-landing-image"
            ></img>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllEvents;

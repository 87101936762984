import { useState } from "react";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import "./AddCareer.css";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { Link } from "react-router-dom";
const AddCareer = () => {
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "requirements",
        validations: ["required"],
        label: "Requirement",
      },
      {
        name: "expertise",
        validations: ["required"],
        label: "Expertise",
      },
      {
        name: "jobRole",
        validations: ["required"],
        label: "Job Role",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("requirements", formData.requirements);
      bodyData.append("expertise", formData.expertise);
      bodyData.append("jobrole", formData.jobRole);
      bodyData.append("image", formData.image[0]);

      try {
        dispatch(startLoading());
        axios
          .post("/api/career", bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Career Added Successfully", "successToast");
            setFormData({
              requirements: "",
              expertise: "",
              image: [],
              jobRole: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Adding Career", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };

  return (
    <>
      <DashboardAddEdit
        heading={"Add Career"}
        parentLink="/dashboard/career"
        parentName={"Careers"}
      >
        <div className="add-services-body">
          <div className="add-service-body-col-1">
            <CustomSingleImageUpload
              // value={formData.image}
              onChange={(value) => {
                handleChange(value, "image");
              }}
              label={"Photo *"}
              dropZoneSizeClassName={"add-service-dropzone-size"}
              error={errors?.image}
              status={clearDragAndDrop}
            ></CustomSingleImageUpload>
          </div>
          <div className="add-service-body-col-2">
            <CustomText
              label={"Job Role *"}
              flex={"1"}
              onChange={(e) => {
                handleChange(e, "jobRole");
              }}
              error={errors?.jobRole}
              value={formData.jobRole}
              placeholder={"Job Role"}
            ></CustomText>
            <div className="emtpty-field-db-form" style={{ flex: "1" }}>
              &nbsp;
            </div>
            <CustomTextArea
              label={"Requirements *"}
              rows={10}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "requirements");
              }}
              error={errors?.requirements}
              value={formData.requirements}
              placeholder={"Requirements"}
            ></CustomTextArea>
            <CustomTextArea
              label={"Expertise *"}
              rows={10}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "expertise");
              }}
              error={errors?.expertise}
              value={formData.expertise}
              placeholder={"Expertise"}
            ></CustomTextArea>
          </div>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/career">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};
export default AddCareer;

import { useState } from "react";
import "./AddClient.css";
import { FaPlus } from "react-icons/fa6";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import { useDispatch } from "react-redux";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
const AddClient = ({ refresh, setRefresh }) => {
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "name",
        validations: ["required"],
        label: "Name",
      },
      {
        name: "country",
        validations: ["required"],
        label: "Country",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("name", formData.name);
      bodyData.append("country", formData.country);
      bodyData.append("image", formData.image[0]);

      try {
        dispatch(startLoading());
        axios
          .post("/api/clients", bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Client Added Successfully", "successToast");
            setFormData({
              name: "",
              image: [],
              country: "",
            });
            setShowPopup(false);
            setRefresh(!refresh);
            setClearDragAndDrop(!clearDragAndDrop);
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Adding Client", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };

  return (
    <>
      <div
        aria-hidden={false}
        className={
          showPopup
            ? "db-client-overlay db-client-overlay-active"
            : "db-client-overlay"
        }
      >
        <div className="db-client-popup-container">
          <div className="db-client-popup-header db-client-popup-heading">
            Add Client
          </div>
          <div className="db-client-popup-body">
            <div
              className="db-client-popup-fields"
              style={{ display: "flex", gap: "20px" }}
            >
              <div>
                <CustomSingleImageUpload
                  value={formData.image}
                  onChange={(value) => {
                    handleChange(value, "image");
                  }}
                  label={"Logo *"}
                  dropZoneSizeClassName={"add-service-dropzone-size"}
                  error={errors?.image}
                  status={clearDragAndDrop}
                ></CustomSingleImageUpload>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                <CustomText
                  label={"Name *"}
                  flex={"100%"}
                  onChange={(e) => {
                    handleChange(e, "name");
                  }}
                  error={errors?.name}
                  value={formData.name}
                  placeholder={"Client Name"}
                ></CustomText>
                <CustomText
                  label={"Country *"}
                  flex={"100%"}
                  onChange={(e) => {
                    handleChange(e, "country");
                  }}
                  error={errors?.country}
                  value={formData.country}
                  placeholder={"Country"}
                ></CustomText>
              </div>
            </div>
            <div className="db-client-popup-buttons">
              <button
                className="add-services-submit-button"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                }}
                className="add-services-clear-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <FaPlus
        className="dashboard-client-details-add-icon-style"
        onClick={() => {
          setShowPopup(!showPopup);
        }}
        style={{ cursor: "pointer" }}
      />
    </>
  );
};

export default AddClient;

import React from "react";
import Landing from "../../containers/simpleLayout/home/landing/Landing";
import Benefits from "../../containers/simpleLayout/home/benefits/Benefits";
import Services from "../../containers/simpleLayout/home/services/Services";
import CustomerBase from "../../containers/simpleLayout/home/customerbase/CustomerBase";
import Testimonial from "../../containers/simpleLayout/home/testimonial/Testimonial";
import CurvedLines from "../../components/SimpleLayout/CurvedLines/CurvedLines";

const Home = () => {
  return (
    <>
      <div>
        <div
          style={{ display: "none", position: "relative", overflow: "hidden" }}
        >
          <CurvedLines
            width={500}
            height={0}
            top={"0px"}
            left={"0px"}
            key="3"
          />
        </div>
      </div>
      <Landing />
      <Benefits />
      <Services />
      <CustomerBase />
      <Testimonial />
    </>
  );
};

export default Home;

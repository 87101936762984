import { useDispatch } from "react-redux";
import "./ContactUsForm.css";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { CountriesList } from "./countryData";
import { useState } from "react";
const ContactUsForm = () => {
  const handleInput = (event) => {
    event.target.parentNode.dataset.replicatedValue = event.target.value;
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "name",
        validations: ["required", "onlyText"],
        label: "Name",
      },
      {
        name: "email",
        validations: ["required", "email"],
        label: "Email",
      },
      {
        name: "phone",
        validations: ["required"],
        label: "Phone",
      },
      {
        name: "country",
        validations: ["required"],
        label: "Country",
      },
      {
        name: "company",
        validations: ["required"],
        label: "Company",
      },
      {
        name: "interest",
        validations: ["required"],
        label: "Interest",
      },
      {
        name: "message",
        validations: ["required"],
        label: "Message",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      const bodydata = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        country: formData.country,
        company: formData.company,
        interestedIn: formData.interest,
        message: formData.message,
      };
      try {
        setLoading(true);
        axios
          .post("/api/general/getintouch", bodydata)
          .then((res) => {
            toast("Inquiry Added Successfully", "successToast");
            setFormData({
              name: "",
              email: "",
              phone: "",
              country: "",
              company: "",
              interest: "",
              message: "",
            });
            setLoading(false);
          })
          .catch((err) => {
            toast("Error in Adding Inquiry", "errorToast");
            setLoading(false);
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        setLoading(false);
      }
    }
  };

  return (
    <div className="contactus-form-container">
      {/* <section> */}
      <div className="contact-us-form-flex">
        <div>
          <div className="contact-us-form-heading">
            <div className="contact-us-form-heading-white">Have a project!</div>
            <div className="contact-us-form-heading-yellow">Let's discuss </div>
          </div>
          <div className="contact-us-form-description">
            Thank you for getting in touch! <br></br>
            Kindly. <br></br>
            Fill the form, have a great day!
          </div>
        </div>
        <div className="contact-us-form-row-collection">
          <div className="contact-us-form-row">
            <div class="contact-us-form">
              <input
                class="cotact-us-input"
                placeholder="Your Name"
                required=""
                type="text"
                onChange={(value) => {
                  handleChange(value, "name");
                }}
                value={formData?.name}
              />
              <span className="form-error-message">{errors?.name}</span>
            </div>
            <div class="contact-us-form">
              <input
                class="cotact-us-input"
                placeholder="Your Email"
                required=""
                type="text"
                onChange={(value) => {
                  handleChange(value, "email");
                }}
                value={formData?.email}
              />
              <span className="form-error-message">{errors?.email}</span>
            </div>
          </div>
          <div className="contact-us-form-row">
            <div class="contact-us-form">
              <input
                class="cotact-us-input"
                placeholder="Phone Number"
                required=""
                type="text"
                onChange={(value) => {
                  handleChange(value, "phone");
                }}
                value={formData?.phone}
              />
              <span className="form-error-message">{errors?.phone}</span>
            </div>
            <div class="contact-us-form">
              <select
                class="cotact-us-input"
                onChange={(value) => {
                  handleChange(value, "country");
                }}
                value={formData?.country}
              >
                <option value="" selected={true} disabled={true}>
                  Country
                </option>
                {CountriesList.map((country) => {
                  return (
                    <option style={{ color: "black" }} value={country.value}>
                      {country.label}
                    </option>
                  );
                })}
              </select>
              <span className="form-error-message">{errors?.country}</span>
            </div>
          </div>
          <div className="contact-us-form-row">
            <div class="contact-us-form">
              <input
                class="cotact-us-input"
                placeholder="Company Name"
                required=""
                type="text"
                onChange={(value) => {
                  handleChange(value, "company");
                }}
                value={formData?.company}
              />
              <span className="form-error-message">{errors?.company}</span>
            </div>
            <div class="contact-us-form">
              <select
                class="cotact-us-input"
                onChange={(value) => {
                  handleChange(value, "interest");
                }}
                value={formData?.interest}
              >
                <option selected={true} disabled={true} value={""}>
                  Interested in
                </option>
                <option
                  style={{ color: "black" }}
                  vaue={"Mobile App Development"}
                >
                  Mobile App Development
                </option>
                <option
                  style={{ color: "black" }}
                  value={"Web App Development"}
                >
                  Web App Development
                </option>
                <option style={{ color: "black" }} value="AI Development">
                  AI Development
                </option>
              </select>
              <span className="form-error-message">{errors?.interest}</span>
            </div>
          </div>
          <div className="contact-us-form-row">
            <div
              class="contact-us-form"
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <div className="grow-wrap">
                <textarea
                  name="text"
                  className="cotact-us-input"
                  rows={2}
                  placeholder="Message"
                  onInput={handleInput}
                  onChange={(value) => {
                    handleChange(value, "message");
                  }}
                  value={formData?.message}
                ></textarea>
                <span className="form-error-message">{errors?.message}</span>
              </div>
            </div>
          </div>
          <div className="contact-us-form-row contact-us-button">
            <button
              type="button"
              className="contact-us-form-submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <div className="button-spinner-container-new">
                  <div className="button-spinner-new"></div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
      {/* </section> */}
    </div>
  );
};

export default ContactUsForm;

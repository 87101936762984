import Polygon from "../../../../components/Polygon/Polygon";
import "./Benefits.css";
import BenefitsImage from "../../../../assets/simpleLayout/home/benefits/Benefits.svg";
import { BenefitsData } from "./BenefitsData";

const Benefits = () => {
  return (
    <section id="home-benefits" className={"home-top-space"}>
      <Polygon
        marginLeft={"-200px"}
        marginTop={"-50px"}
        width={450}
        height={450}
      />

      <div className="benefits-heading-main benefits-heading-tab">
        <span className="benefits-color-heading">Benefits</span>{" "}
        <span className="benefits-contd-heading">
          of working <br></br>with us
        </span>
      </div>
      <div className="benefits-main-container">
        <div>
          <img
            src={BenefitsImage}
            alt={"Sample"}
            className="benefits-image"
          ></img>
        </div>
        <div>
          <div className="benefits-heading-main benefits-heading-big">
            <span className="benefits-color-heading">Benefits</span>{" "}
            <span className="benefits-contd-heading">
              of working <br></br>with us
            </span>
          </div>
          <div className="benefit-line-divider"></div>
          <div className="benefit-line">
            {BenefitsData.map((benefit, index) => {
              return (
                <div className="benefit" key={benefit.id}>
                  <div>
                    <div className="benefits-icon-container">
                      <img src={benefit.icon} alt="icon2"></img>
                    </div>
                  </div>
                  <div>
                    <div className="single-benefit-heading">
                      {benefit.heading}
                    </div>
                    <div className="single-benefit-description">
                      {benefit.description}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Benefits;

import Polygon from "../../../../components/Polygon/Polygon";
import "./CustomerBase.css";
import TestimonialImage from "../../../../assets/simpleLayout/home/customerBase/Testimonial.svg";
const CustomerBase = () => {
  return (
    <section id="home-customer-base" className={"home-top-space"}>
      <div className="customer-base-polygon">
        <Polygon
          marginLeft={"-120px"}
          marginTop={"50px"}
          width={500}
          height={500}
        />
      </div>
      <div className="customer-base-container">
        <div>
          <span className="customer-base-black-heading">We Are </span>
          <span className="customer-base-orange-heading">Making</span> <br></br>
          <span className="customer-base-orange-heading">Ideas Better</span>
          <span className="customer-base-black-heading"> For Everyone</span>
        </div>
        <div>
          <img
            className="customer-base-image"
            src={TestimonialImage}
            alt="world customer base"
          ></img>
        </div>
      </div>
    </section>
  );
};

export default CustomerBase;

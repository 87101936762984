import React, { useEffect, useState, useRef } from "react";
import "./SingleImageUpload.css";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/actions/loaderActions";

function SingleImageUpload({
  transferImageToParent,
  status,
  retrieveImage,
  mode,
  onlyOneImage,
  sizeClassName,
}) {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const inputRef = useRef(null);

  function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
  function extractFilenameAndMimeType(url) {
    console.log("URL: " + url);
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    const extension = filename.split(".").pop();
    let mimeType;
    switch (extension.toLowerCase()) {
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        break;
      case "png":
        mimeType = "image/png";
        break;
      case "gif":
        mimeType = "image/gif";
        break;
      default:
        mimeType = "application/octet-stream"; // Default MIME type for unknown file types
    }

    return { filename, mimeType };
  }
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (retrieveImage && retrieveImage.length > 0) {
      console.log(retrieveImage);
      // Array to store base64 strings
      const base64Strings = [];
      const conversionPromises = [];
      const newfiles = []; // Define newfiles array here

      // Iterate through each URL in retrieveImage array
      for (let i = 0; i < retrieveImage.length; i++) {
        const imageUrl = retrieveImage[i];
        if (typeof imageUrl === "object") {
          return;
        }
        const { filename, mimeType } = extractFilenameAndMimeType(imageUrl);
        // dispatch(startLoading());
        setLoading(true);
        // Convert URL to Blob object
        conversionPromises.push(
          fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              // dispatch(stopLoading());
              setLoading(false);
              const file = new File([blob], filename, { type: mimeType });
              newfiles.push(file);

              // Convert Blob to base64 string
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  base64Strings.push(reader.result);
                  resolve();
                };
                reader.readAsDataURL(blob);
              });
            })
        );
      }
      Promise.all(conversionPromises).then(() => {
        setImagePreviews(base64Strings);
        setImageFiles(newfiles);
      });
    }
  }, [retrieveImage]);

  function dropHandler(ev) {
    ev.preventDefault();
    if (mode !== "editMode") {
      return; // Exit the function without performing any action
    }

    const handleFiles = (files) => {
      const filePromises = files.map(async (file) => {
        const base64String = await convertImageToBase64(file);
        return { file, base64String };
      });

      Promise.all(filePromises)
        .then((imageDataArray) => {
          const filteredFilesSet = new Set();
          const base64StringsSet = new Set();

          imageDataArray.forEach((data) => {
            if (data !== null) {
              filteredFilesSet.add(data.file);
              base64StringsSet.add(data.base64String);
            }
          });

          const filteredFiles = [...filteredFilesSet];
          const base64Strings = [...base64StringsSet];

          setImageFiles((prevFiles) => {
            return [...prevFiles, ...filteredFiles];
          });

          setImagePreviews((prevPreviews) => {
            return [...prevPreviews, ...base64Strings];
          });
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
        });
    };

    if (ev.type === "drop") {
      if (ev.dataTransfer.items) {
        const files = [...ev.dataTransfer.items].map((item) =>
          item.getAsFile()
        );
        handleFiles(files);
      }
    } else if (ev.type === "change") {
      if (ev.target.files) {
        const files = [...ev.target.files];
        handleFiles(files);
      }
    }
  }

  useEffect(() => {
    setImageFiles([]);
    setImagePreviews([]);
  }, [status]);

  function dragOverHandler(ev) {
    ev.preventDefault();
  }

  function removeImagePreview(index) {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedImageFiles = [...imageFiles];
    updatedImageFiles.splice(index, 1);
    setImageFiles(updatedImageFiles);
  }

  transferImageToParent(imageFiles);
  // console.log('op',imageFiles);
  // console.log(retrievedImages);

  useEffect(() => {
    return () => {
      imagePreviews.forEach(URL.revokeObjectURL);
    };
  }, [imagePreviews]);
  // console.log(imagePreviews);
  // transferImageToParent(imageFiles);
  // console.log(imageFiles);
  return (
    <>
      <div
        id="drop_zone"
        className={sizeClassName}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onClick={(event) => {
          if (!event.target.classList.contains("close-button")) {
            inputRef.current.click();
          }
        }}
      >
        {loading ? (
          <p className="drag-and-drop-text">Loading</p>
        ) : (
          <p className="drag-and-drop-text">
            Drag and drop or click here to select file{" "}
            {onlyOneImage ? "" : "(You can add Multiple photos)"}
          </p>
        )}

        <div className="image-previews">
          {imagePreviews.map((preview, index) => (
            <div key={index} className="image-preview">
              {mode === "editMode" && (
                <button
                  className="close-button"
                  type="button"
                  onClick={() => removeImagePreview(index)}
                >
                  &times;
                </button>
              )}
              <img src={preview} alt={`Preview ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={dropHandler}
        style={{ display: "none" }} // Hide the input visually
      />
    </>
  );
}

export default SingleImageUpload;

import ContactUsForm from "../../containers/simpleLayout/contactus/contactUsForm/ContactUsForm";
import ContactUsLanding from "../../containers/simpleLayout/contactus/contactUsLanding/ContactUsLanding";
import ContactUsMap from "../../containers/simpleLayout/contactus/contactUsMap/ContactUsMap";

const ContactUsPage = () => {
  return (
    <>
      <ContactUsLanding />
      <ContactUsForm />
      <ContactUsMap />
    </>
  );
};

export default ContactUsPage;

import PortfolioLanding from "../../../containers/simpleLayout/portfolio/portfolioLanding/PortfolioLanding";
import PortfolioProject from "../../../containers/simpleLayout/portfolio/portfolioProjects/PortfolioProject";

const PortfolioPage = () => {
  return (
    <div>
      <PortfolioLanding />
      <PortfolioProject />
    </div>
  );
};
export default PortfolioPage;

import "./ContactUsLanding.css";
import Image1 from "../../../../assets/simpleLayout/contactus/1.jpeg";
import Image2 from "../../../../assets/simpleLayout/contactus/2.jpeg";
import Image3 from "../../../../assets/simpleLayout/contactus/3.jpeg";
import Image4 from "../../../../assets/simpleLayout/contactus/4.jpeg";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import Polygon from "../../../../components/Polygon/Polygon";
const ContactUsLanding = () => {
  return (
    <>
      <Polygon
        marginLeft={"-160px"}
        marginTop={"-250px"}
        width={400}
        height={400}
      />
      <CurvedLines
        width={2000}
        height={400}
        top={"0px"}
        left={"-80px"}
        key="1"
      />
      <section
        className="contact-us-landing-section"
        id="contact-us-landing-section"
      >
        <div className="contact-us-landing-container">
          <div className="contact-us-landing-headings">
            <span className="contactus-landing-heading">
              <span className="contact-us-landing-heading-part-1">
                Discover{" "}
              </span>
              <span className="contact-us-landing-heading-part-2">Us</span>
            </span>

            <div className="contactus-landing-desc">
              We are here to help you;
              <span className="contactus-landing-mobile-optional">
                <br></br>Our experts are available to answer any questions
                <br className="contact-us-landing-br-2"></br>you might have.
                We've got the answers.
              </span>
            </div>
          </div>
          <div className="contact-us-landing-image-container">
            <img
              src={Image1}
              alt="landing pic 1"
              className="contactus-landing-img1 contactus-landing-img"
            ></img>
            <img
              src={Image2}
              alt="landing pic 2"
              className="contactus-landing-img2 contactus-landing-img"
            ></img>
            <img
              src={Image3}
              alt="landing pic 3"
              className="contactus-landing-img3 contactus-landing-img"
            ></img>
            <img
              src={Image4}
              alt="landing pic 4"
              className="contactus-landing-img4 contactus-landing-img"
            ></img>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsLanding;

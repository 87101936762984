import "./Polygon.css";
import PolygonSvg from "./polygon.svg";
const Polygon = ({
  marginLeft,
  marginTop,
  width,
  height,
  marginRight,
  className,
}) => {
  return (
    <div
      className={`polygon-svg-container ${className}`}
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop,
        marginRight: marginRight,
      }}
    >
      {/* <img
        src={PolygonSvg}
        alt="polygon"
        style={{ width: width, height: height }}
        className="polygon-svg-style"
        draggable={false}
      ></img> */}
    </div>
  );
};

export default Polygon;

import "./CareerCard.css";
import { Link } from "react-router-dom";
const CareerCard = ({ position, image, id }) => {
  return (
    <div className="career-card-main-container">
      <div className="career-card-body">
        <div className="career-card-header">{position}</div>
        <div className="career-card-image-container">
          <img src={image} alt="career pic" className="career-card-image"></img>
        </div>
        <div>
          <Link to={id} className="career-card-apply-button">
            <button>Apply</button>
          </Link>
        </div>
        {/* <div>
          <Link className="career-card-see-detail">See Detail</Link>
        </div> */}
      </div>
    </div>
  );
};
export default CareerCard;

import "./DashboardClient.css";
import { headerData, caption } from "./TableConfig";
import Table from "../../../../components/Table/Table";
import {
  deleteButton,
  editButton,
} from "../../../../layouts/dashboardLayout/DashboardLayout";
import AddClient from "../../clients/addClient/AddClient";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import axios from "axios";
import { showToastMessage } from "../../../../redux/actions/toastActions";
import { Link } from "react-router-dom";
import PopUp from "../../../../components/PopUp/PopUp";
import Pagination from "../../../../components/Pagination/Pagination";
import ExceptionComponent from "../../../../components/ExceptionComponent/ExceptionComponent";
import EditClient from "../../clients/editClient/EditClient";

const DashboardClient = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  // Related to Pagination
  const limit = { search: 5, fetch: 4 };
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (page) => setCurrentPage(page);
  // Related to search
  const [mode, setMode] = useState("get");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (mode === "get") {
      dispatch(startLoading());
      axios
        .get("/api/clients", {
          params: {
            limit: limit.fetch,
            page: currentPage,
          },
        })
        .then((res) => {
          dispatch(stopLoading());
          setData(res.data);
        })
        .catch((err) => {
          dispatch(stopLoading());
          console.log(err);
          setErrorApi(true);
        });
    }
    // if (mode === "search") {
    //   dispatch(startLoading());
    //   axios
    //     .get("/api/projects/search", {
    //       params: {
    //         searchTerm: searchTerm,
    //         limit: limit.search,
    //         page: currentPage,
    //       },
    //     })
    //     .then((res) => {
    //       dispatch(stopLoading());
    //       setData(res.data);
    //     })
    //     .catch((err) => {
    //       dispatch(stopLoading());
    //       console.log(err);
    //       setErrorApi(true);
    //     });
    // }
  }, [currentPage, mode, refresh, searchTerm]);

  const onDelete = async (id) => {
    try {
      axios
        .delete(`/api/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setRefresh(!refresh);
          setCurrentPage(1);
          dispatch(
            showToastMessage("Client Deleted Successfully", "successToast")
          );
        })
        .catch((err) => {
          dispatch(showToastMessage("Client Deletion Failed", "errorToast"));
        });
    } catch (error) {
      dispatch(showToastMessage("Something went wrong", "errorToast"));
    }
  };

  const tableData = Array.isArray(data?.data)
    ? data?.data.map((data, index) => {
        return {
          tableViewInfo: [
            <img
              src={data.image}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt={data.name}
              className="dashboard-single-client-image"
            />,
            data.name,
            data.country,
            <div className="all-services-action-icons">
              <EditClient
                id={data._id}
                refresh={refresh}
                setRefresh={setRefresh}
              >
                {editButton}
              </EditClient>
              <PopUp
                message="Do you want to delete this client ?"
                onYes={[
                  () => {
                    onDelete(data._id);
                  },
                ]}
                buttonText={deleteButton}
                className="dashboard-form-delete-button"
              />
            </div>,
          ],
          onClickTr: ``,
          id: index,
        };
      })
    : [];
  return (
    <>
      <div className="dashboard-client-details-container">
        <div className="dashboard-client-details-container-header">
          <div className="dashboard-client-details-heading">Client Details</div>
          <div className="dashboard-client-details-add-icon">
            <AddClient refresh={refresh} setRefresh={setRefresh} />
          </div>
        </div>
        <div className="dashboard-single-client-collection">
          {Array.isArray(data?.data) ? (
            data?.data.length !== 0 ? (
              <>
                <Table
                  caption={caption}
                  headerData={headerData}
                  tableData={tableData}
                  disableRowClick={true}
                  showthead={false}
                  showBorder={false}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPage={data?.totalPages}
                  paginate={paginate}
                />
              </>
            ) : (
              <ExceptionComponent type="noresult" />
            )
          ) : errorApi ? (
            <ExceptionComponent type="error" />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default DashboardClient;

import React, { useEffect, useState } from "react";
import { TestimonialData } from "../TestimonialData";
import "./TestimonialSlider.css";
import TestimonialView from "./TestimonialView/TestimonialView";
import axios from "axios";
const TestimonialSlider = ({ getTestimonialData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialCount = TestimonialData.length;

  const [testimonialData, setTestimonialData] = useState([]);
  useEffect(() => {
    axios
      .get("/api/reviews", { params: { limit: 3, page: 1 } })
      .then((res) => {
        getTestimonialData(res.data.data);
        setTestimonialData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialCount);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [testimonialCount]);

  useEffect(() => {
    if (document.getElementById(`i_${currentIndex + 1}`)) {
      document.getElementById(`i_${currentIndex + 1}`).checked = true;
    }
  }, [currentIndex]);

  return (
    <div className="testimonialSlider">
      {Array.isArray(testimonialData)
        ? testimonialData.map((_, index) => (
            <input
              key={index}
              className="testimonial-input"
              type="radio"
              name="switch"
              id={`i_${index + 1}`}
              defaultChecked={index === 0}
            />
          ))
        : null}

      <div className="wrapper">
        {Array.isArray(testimonialData)
          ? testimonialData.map((testimonial, index) => {
              return (
                <div className="slide" key={index}>
                  <div className={`content content${index + 1}`}>
                    <TestimonialView
                      personImage={testimonial.image}
                      name={testimonial.name}
                      position={
                        testimonial.position + " at " + testimonial.company
                      }
                      review={testimonial.description}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <div className="controls">
        {Array.isArray(testimonialData)
          ? testimonialData.map((_, index) => (
              <label
                key={index}
                className="testimonial-label"
                htmlFor={`i_${index + 1}`}
              >
                ⬤
              </label>
            ))
          : null}
      </div>
    </div>
  );
};

export default TestimonialSlider;

import "./Dashboard.css";
import DashboardClient from "./dashboardClientDetails/DashboardClient";
import DashboardHeader from "./dashboardHeader/DashboardHeader";
import DashboardSummary from "./dashboardSummary/DashboardSummary";
import DashboardYourPlan from "./dashboardYourPlan/DashboardYourPlan";
const Dashboard = () => {
  return (
    <div className="">
      <div className="dashboard-main-header">
        <DashboardHeader />
      </div>
      <div>
        <DashboardSummary />
      </div>
      <div className="dashboard-main-client-blog-grid">
        <div className="dashboard-main-client">
          <DashboardClient />
        </div>
        <div className="dashboard-main-blog">
          <DashboardYourPlan />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;

import "./AllCareers.css";
import { allCareerData } from "./AllCareersData";
import CareerCard from "./careerCard/CareerCard";
import Polygon from "../../../../components/Polygon/Polygon";
import CareerLanding from "../CareerLanding";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";

const AllCareers = () => {
  const { data, loadMore, isError } = useInfiniteScroll("/api/career", 3);
  return (
    <section id="careers-page">
      <CareerLanding />
      {!isError && (
        <>
          <div className="careers-page-polygon">
            <Polygon
              marginLeft={"240px"}
              marginTop={"880px"}
              width={500}
              height={500}
            />
            <Polygon
              marginLeft={"-30px"}
              marginTop={"1300px"}
              width={300}
              height={300}
            />
          </div>
          <div>
            <div className="careers-page-career-heading-container">
              <span className="careers-page-career-heading-color">Job </span>
              <span className="careers-page-career-heading-dark">Openings</span>
            </div>
            <div className="careers-page-career-items">
              {Array.isArray(data)
                ? data.map((career, index) => {
                    return (
                      <CareerCard
                        key={index}
                        position={career.jobrole}
                        image={career.image}
                        id={career._id}
                      />
                    );
                  })
                : null}
            </div>
            {loadMore}
          </div>
        </>
      )}
    </section>
  );
};

export default AllCareers;

import { useEffect, useState } from "react";
import {
  BlogIcon,
  CareerIcon,
  EventsIcon,
  ServicesIcon,
} from "../../../../layouts/dashboardLayout/sidebar/Icons";
import "./DashboardSummary.css";
import DashboardSummaryDataCard from "./dashboardSummaryDataCard/DashboardSummaryDataCard";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
const DashboardSummary = () => {
  const [count, setCount] = useState({
    service: 0,
    career: 0,
    event: 0,
    blog: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(startLoading());
      axios
        .get("/api/general/getcount")
        .then((res) => {
          dispatch(stopLoading());
          setCount(res.data);
        })
        .catch((err) => {
          dispatch(stopLoading());
        });
    } catch (error) {
      dispatch(stopLoading());
    }
  }, []);
  return (
    <div className="dashboard-summary-container">
      <DashboardSummaryDataCard
        icon={ServicesIcon("dashboard-summary-svg-icon-style")}
        iconClass="dashboard-summary-icon-service"
        heading="Services"
        value={count.service}
      />
      <DashboardSummaryDataCard
        icon={CareerIcon("dashboard-summary-svg-icon-style")}
        iconClass="dashboard-summary-icon-career"
        heading="Career"
        value={count.career}
      />
      <DashboardSummaryDataCard
        icon={EventsIcon("dashboard-summary-svg-icon-style")}
        iconClass="dashboard-summary-icon-events"
        heading="Events"
        value={count.event}
      />
      <DashboardSummaryDataCard
        icon={BlogIcon("dashboard-summary-svg-icon-style")}
        iconClass="dashboard-summary-icon-blog"
        heading="Blogs"
        value={count.blog}
      />
    </div>
  );
};
export default DashboardSummary;

import "./PortfolioProject.css";
import MainProject from "../../../../assets/simpleLayout/portfolio/projects/mainproject.jpeg";
import { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "../../../../components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { TrimToNumOfCharacters } from "../../../../functions/TextFunctions";
import SpinnerInside from "../../../../components/SpinnerInside/SpinnerInside";

const PortfolioProject = () => {
  const [data, setData] = useState({});
  // Related to Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (page) => setCurrentPage(page);
  const [errorApi, setErrorApi] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/projects", {
        params: {
          limit: 4,
          page: currentPage,
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setErrorApi(true);
      });
  }, [currentPage]);

  return (
    !errorApi && (
      <section>
        <SpinnerInside loading={loading}>
          <div className="projects-container">
            <div className="portfolio-project-main-project-container">
              <img
                src={MainProject}
                alt="main-project"
                className="portfolio-project-main-project"
              ></img>
              <div className="portfolio-project-main-project-text">
                <div className="portfolio-project-main-project-text-type">
                  TECH
                </div>
                <div className="portfolio-project-main-project-text-heading">
                  The Power of UI/UX: Creating Engaging Digital Experiences
                </div>
              </div>
            </div>
            <div>
              <div className="portfolio-project-sub-projects-container">
                {Array.isArray(data?.data)
                  ? data?.data.map((project, index) => {
                      return (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={project._id}
                        >
                          <div className="portfolio-other-project-container">
                            <img
                              src={project.image}
                              alt="other-project"
                              className="portfolio-other-project-image"
                            ></img>
                            <div className="portfolio-other-project-heading">
                              {project.name}
                            </div>
                            <div className="portfolio-other-project-desc">
                              {TrimToNumOfCharacters(100, project.description)}
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  : null}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPage={data?.totalPages}
                paginate={paginate}
              />
            </div>
          </div>
        </SpinnerInside>
      </section>
    )
  );
};

export default PortfolioProject;

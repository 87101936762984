import { useSelector } from "react-redux";
import spinner1 from "./Spinner1.module.css";
const SpinnerNew = ({ loading, type }) => {
  const { loader } = useSelector((state) => state.loader);
  return <div className={loader?spinner1["loader1"]:`${spinner1["loader1"]+" "+spinner1["loader1-deactive"]}`}></div>;
  // if (loader) {
  //   if (type === "spinnerhome" || type === undefined) {
  //     return <div className={spinner1["loader1"]}></div>;
  //   } else {
  //     return <div className={spinner1["loader1"]}></div>;
  //   }
  // }
};
export default SpinnerNew;

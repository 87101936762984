import React, { useState } from "react";
import Carousel from "../../../../components/SimpleLayout/Carousel/Carousel";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import "./Landing.css";
import Polygon from "../../../../components/Polygon/Polygon";
import LandingImage from "../../../../assets/simpleLayout/home/landing/LandingImage.svg";

import { Link } from "react-router-dom";
const Landing = () => {
  const [isError, setIsError] = useState(false);
  return (
    <div>
      <Polygon
        marginLeft={"-120px"}
        marginTop={"-250px"}
        width={400}
        height={400}
      />
      <div className="polygon2-landing">
        <Polygon width={400} height={400} />
      </div>
      <CurvedLines width={2000} height={400} top={"0px"} left={"0px"} key="1" />
      <section id="home-page-landing">
        <div className={"landing-home-container"}>
          <div className={"landing-home-left-container"}>
            <div className={"landing-home-main-heading"}>
              Build & scale up <br className="landing-home-break"></br>your
              Project<br className="landing-home-break"></br> with us.
            </div>

            <div className={"landing-home-description-text"}>
              Comprehensive development service.
            </div>
            <Link style={{ textDecoration: "none" }} to="/contactus">
              <button className={"landing-button"}>
                <span className="home-landing-web-content">
                  Book a free consultation
                </span>
                <span className="home-landing-mobile-content">
                  Free Consultation
                </span>
              </button>
            </Link>
          </div>
          <div className="landing-home-image-container">
            <img
              src={LandingImage}
              alt="landing"
              className="landing-image"
            ></img>
          </div>
        </div>
        {!isError && (
          <div className={"landing-carousel"}>
            <div className={"landing-home-carousel-heading"}>
              200+ HAPPY CUSTOMERS
            </div>
            <Carousel setIsError={setIsError} />
          </div>
        )}
      </section>
    </div>
  );
};
export default Landing;

import { useState } from "react";
import "./SearchBox.css";
const SearchBox = ({ className, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const handleOnChange = () => {
    if (onChange) {
      onChange(searchTerm);
      setSearchTerm("");
    }
  };
  return (
    <div className={className}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        className="search-box-input-field"
        placeholder={"Find Your Topic"}
      ></input>
      <button className="search-box-button" onClick={handleOnChange}>
        Go
      </button>
    </div>
  );
};
export default SearchBox;

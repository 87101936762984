import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import Logo from "../../../assets/general/logo.png";
import { Link, useLocation } from "react-router-dom";
import { SidebarLinks } from "./SidebarConfig";
import { IoClose } from "react-icons/io5";
import { IoMenu } from "react-icons/io5";
const Sidebar = () => {
  const location = useLocation();
  const [closeSideBar, setCloseSideBar] = useState(false);
  useEffect(() => {
    const menu = document.querySelector(".hamburger-menu-sidebar");
    const closemenu = document.querySelector(".sidebar-close-menu");
    const menuList = document.querySelector(".dashboard-sidebar-nav");

    const handleMenuClick = () => {
      menuList.classList.toggle("active-sidebar");
    };

    if (closeSideBar) {
      menuList.classList.remove("active-sidebar");
      setCloseSideBar(false);
    }

    const closeMenuOutsideClick = (event) => {
      if (!menuList.contains(event.target) && !menu.contains(event.target)) {
        menuList.classList.remove("active-sidebar");
      }
    };

    menu.addEventListener("click", handleMenuClick);
    closemenu.addEventListener("click", handleMenuClick);
    document.addEventListener("click", closeMenuOutsideClick);

    return () => {
      // Cleanup the event listeners when the component unmounts
      menu.removeEventListener("click", handleMenuClick);
      closemenu.removeEventListener("click", handleMenuClick);
      document.removeEventListener("click", closeMenuOutsideClick);
    };
  }, [closeSideBar]);

  return (
    <React.Fragment>
      <div className="hamburger-menu-sidebar">
        <IoMenu />
      </div>
      <nav className="dashboard-sidebar-nav">
        <div className="dashboard-sidebar-image-container">
          <img
            src={Logo}
            alt="sidebar-logo"
            className="dashboard-sidebar-image"
            draggable={false}
          ></img>
          <div className="sidebar-close-menu">
            <IoClose />
          </div>
        </div>
        <div className="dashboard-sidebar-menu-container">
          {SidebarLinks.map((sidebarlink, index) => {
            return (
              <Link to={sidebarlink.linkPath}>
                <div
                  className={
                    "sidebar-menu" +
                    (location.pathname.split("/")[2] ===
                    sidebarlink.linkPath.split("/")[2]
                      ? " sidebar-menu-active"
                      : "")
                  }
                  onClick={() => {
                    setCloseSideBar(true);
                  }}
                >
                  {sidebarlink.linkIcon("sidebar-svg-icon")}{" "}
                  {sidebarlink.linkName}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="dashboard-sidebar-info-container">
          <div className="side-bar-info-line-1">NilaviTech - Admin Panel </div>
          <div className="side-bar-info-line-2">Made by NilaviTech</div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Sidebar;

import axios from "axios";
import { useState, useEffect, useRef } from "react";

const useInfiniteScroll = (url, limit) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const currentFetchId = useRef(0);
  const loader = useRef(null);
  const isFirstRun = useRef(true); // Ref to track initial render
  const [isError, setIsError] = useState(false);

  const fetchData = async (page, fetchId) => {
    if (totalPages !== null && page > totalPages) return;
    setLoading(true);
    try {
      const response = await axios.get(url, {
        params: {
          limit: limit || 3,
          page: page,
        },
      });
      setLoading(false);
      if (fetchId === currentFetchId.current) {
        setData((prev) => [...prev, ...response.data.data]);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    currentFetchId.current += 1;
    const fetchId = currentFetchId.current;
    fetchData(page, fetchId);
  }, [page, url]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && totalPages !== null) {
          setPage((prev) => Math.min(prev + 1, totalPages)); // Prevent going beyond total pages
        }
      },
      {
        threshold: 1.0,
      }
    );
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loading, totalPages]);

  useEffect(() => {
    // If the hook is rendering for the first time or data is already loaded, don't set loading
    if (isFirstRun.current || data.length > 0) {
      isFirstRun.current = false;
      return;
    }
    setLoading(true);
  }, [data]);

  return {
    data,
    loader,
    loading,
    isError,
    loadMore: (
      <>
        {loading && (
          <div className="infinite-loading-component">
            <div
              className="spinner-container-new"
            >
              <div className="spinner-new"></div>
            </div>
          </div>
        )}
        <div ref={loader} />
      </>
    ),
  };
};

export default useInfiniteScroll;

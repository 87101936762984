import "./CurvedLines.css";
const CurvedLines = ({
  width = "100%",
  height = "100%",
  top,
  left,
  zIndex,
  cliptop,
  color,
}) => {
  return (
    <div
      className="curved-lines-container"
      style={{
        top: top,
        width: `calc(100% - ${left})`,
        left: left,
        zIndex: zIndex || "-100",
        clipPath: `inset(${cliptop || 0} 0 0 0px)`,
        WebkitClipPath: `inset(${cliptop || 0} 0 0 0px)`,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="clip">
            <rect width={width} height={height} />
          </clipPath>
        </defs>
        <g clipPath="url(#clip)">
          <path
            d="M432.802 -2.18555C680.621 9.45112 462.611 227.217 651.617 354.492C824.83 471.133 1125.86 212.886 1165.05 578.753C1191.89 829.242 1272.43 922.505 1477.58 976.842"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M444.067 -15.6094C691.885 -3.97271 473.876 213.793 662.882 341.069C836.095 457.709 1137.12 199.462 1176.32 565.329C1203.15 815.818 1283.69 909.081 1488.85 963.418"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M455.332 -29.0342C703.15 -17.3975 485.141 200.368 674.146 327.644C847.359 444.285 1148.39 186.037 1187.58 551.904C1214.42 802.393 1294.95 895.657 1500.11 949.994"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M466.596 -42.459C714.415 -30.8223 496.405 186.944 685.411 314.219C858.624 430.86 1159.65 172.613 1198.85 538.479C1225.68 788.969 1306.22 882.232 1511.38 936.569"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M477.861 -55.8838C725.679 -44.2471 507.67 173.519 696.676 300.794C869.889 417.435 1170.92 159.188 1210.11 525.055C1236.95 775.544 1317.48 868.807 1522.64 923.144"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M489.125 -69.3086C736.944 -57.6719 518.935 160.094 707.94 287.369C881.153 404.01 1182.18 145.763 1221.38 511.63C1248.21 762.119 1328.75 855.382 1533.91 909.719"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M500.39 -82.7334C748.209 -71.0967 530.199 146.669 719.205 273.945C892.418 390.585 1193.44 132.338 1232.64 498.205C1259.47 748.694 1340.01 841.957 1545.17 896.294"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M511.655 -96.1582C759.473 -84.5215 541.464 133.244 730.47 260.52C903.683 377.161 1204.71 118.913 1243.9 484.78C1270.74 735.269 1351.28 828.532 1556.44 882.87"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M522.919 -109.582C770.738 -97.9454 552.728 119.821 741.734 247.096C914.947 363.737 1215.97 105.49 1255.17 471.356C1282 721.845 1362.54 815.109 1567.7 869.446"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M534.184 -123.007C782.003 -111.37 563.993 106.396 752.999 233.671C926.212 350.312 1227.24 92.0648 1266.43 457.931C1293.27 708.421 1373.81 801.684 1578.97 856.021"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M545.449 -136.432C793.267 -124.795 575.258 92.971 764.263 220.246C937.477 336.887 1238.5 78.64 1277.7 444.507C1304.53 694.996 1385.07 788.259 1590.23 842.596"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M556.713 -149.856C804.532 -138.22 586.522 79.5462 775.528 206.822C948.741 323.462 1249.77 65.2151 1288.96 431.082C1315.8 681.571 1396.34 774.834 1601.5 829.171"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M567.978 -163.281C815.797 -151.645 597.787 66.1214 786.793 193.397C960.006 310.037 1261.03 51.7903 1300.23 417.657C1327.06 668.146 1407.6 761.409 1612.76 815.747"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M579.242 -176.706C827.061 -165.069 609.051 52.6966 798.057 179.972C971.27 296.613 1272.3 38.3655 1311.49 404.232C1338.33 654.721 1418.87 747.985 1624.02 802.322"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M590.507 -190.13C838.325 -178.493 620.316 39.2728 809.322 166.548C982.535 283.189 1283.56 24.9417 1322.76 390.808C1349.59 641.298 1430.13 734.561 1635.29 788.898"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M601.772 -203.555C849.591 -191.918 631.581 25.848 820.587 153.123C993.8 269.764 1294.83 11.5169 1334.02 377.384C1360.86 627.873 1441.4 721.136 1646.55 775.473"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M613.036 -216.979C860.855 -205.343 642.845 12.4232 831.851 139.699C1005.06 256.339 1306.09 -1.9079 1345.29 363.959C1372.12 614.448 1452.66 707.711 1657.82 762.048"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M624.301 -230.404C872.119 -218.768 654.11 -1.00163 843.116 126.274C1016.33 242.914 1317.36 -15.3327 1356.55 350.534C1383.39 601.023 1463.92 694.286 1669.08 748.623"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M635.566 -243.829C883.385 -232.192 665.375 -14.4264 854.381 112.849C1027.59 229.49 1328.62 -28.7575 1367.82 337.109C1394.65 587.598 1475.19 680.862 1680.35 735.199"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
          <path
            d="M646.83 -257.254C894.649 -245.617 676.639 -27.8512 865.645 99.4241C1038.86 216.065 1339.88 -42.1823 1379.08 323.684C1405.91 574.174 1486.45 667.437 1691.61 721.774"
            stroke={color || "#F2F2F2"}
            stroke-width="1.75246"
          />
        </g>
      </svg>
    </div>
  );
};

export default CurvedLines;

import Polygon from "../../../components/Polygon/Polygon";
import "./About.css";
import AboutLandingImage from "../../../assets/simpleLayout/about/images/aboutLanding.png";
import AboutWhoWeAre from "../../../assets/simpleLayout/about/images/aboutWhoWeAre.png";
// import AboutLandingTopRightVector from "../../../assets/simpleLayout/about/vectors/aboutLandingTopVector.png";
const About = () => {
  return (
    <>
      {/* <img
        src={AboutLandingTopRightVector}
        alt="top-right-vector"
        className="about-landing-right-polygon"
      /> */}
      <div className="about-landing-design">
        <section id="abot-page-landing">
          <div className="about-polygon1">
            <Polygon
              marginLeft={"-180px"}
              marginTop={"80px"}
              width={400}
              height={400}
            />
          </div>
          <div className="about-polygon2">
            <Polygon
              marginLeft={"440px"}
              marginTop={"-350px"}
              width={400}
              height={400}
            />
          </div>
          <div className="about-landing-top-heading-tab">
            <span className="about-landing-primary">We Help you </span>{" "}
            <br className="about-landing-break"></br>
            <span className="about-landing-black">
              to grow your <br className="about-landing-break"></br>Business
            </span>
          </div>
          <div className="about-landing-container about-landing-container-top">
            <div>
              <div className="about-landing-top-heading-big">
                <span className="about-landing-primary">We Help you </span>{" "}
                <br className="about-landing-break"></br>
                <span className="about-landing-black">
                  to grow your <br className="about-landing-break"></br>
                  Business
                </span>
                <div className="about-heading-underline"></div>
              </div>
              <div className="about-landing-divider"></div>
              <div className="about-landing-description">
                To provide innovative and reliable software solutions{" "}
                <br className="about-landing-break"></br>that empower businesses
                to thrive in the digital age.
              </div>
            </div>
            <div className="about-landing-image-container">
              <img
                src={AboutLandingImage}
                alt="about landing"
                className="about-landing-image"
              ></img>
            </div>
          </div>
        </section>
      </div>

      <section id="about-page-who-we-are">
        <div className="about-landing-container  about-landing-container-bottom">
          <div className="about-landing-image-container">
            <img
              src={AboutWhoWeAre}
              alt="about landing"
              className="about-who-we-are-image"
            ></img>
          </div>
          <div>
            <div className="who-we-are-heading-set">
              <span className="about-heading-set">
                <span className="about-landing-black-2">We </span>
                <span className="about-landing-secondary">are</span>
              </span>
              <span className="about-landing-primary-vertical-line">
                &nbsp;
              </span>
            </div>
            <div className="about-landing-divider"></div>
            <div className="about-who-we-are-description">
              To be the leading provider of cutting-edge software services
              globally, delivering exceptional value to clients and fostering a
              culture of continuous innovation and growth within our team.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;

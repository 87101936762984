import {
  BlogIcon,
  CareerIcon,
  DashboardIcon,
  EventsIcon,
  PortfolioIcon,
  ReviewsIcon,
  ServicesIcon,
  TeamsIcon,
} from "./Icons";

export const SidebarLinks = [
  {
    linkName: "Dashboard",
    linkPath: "/dashboard",
    linkIcon: DashboardIcon,
  },
  {
    linkName: "Services",
    linkPath: "/dashboard/services",
    linkIcon: ServicesIcon,
  },
  {
    linkName: "Career",
    linkPath: "/dashboard/career",
    linkIcon: CareerIcon,
  },
  {
    linkName: "Events",
    linkPath: "/dashboard/events",
    linkIcon: EventsIcon,
  },
  {
    linkName: "Portfolio",
    linkPath: "/dashboard/portfolio",
    linkIcon: PortfolioIcon,
  },
  {
    linkName: "Blog",
    linkPath: "/dashboard/blogs",
    linkIcon: BlogIcon,
  },
  {
    linkName: "Reviews",
    linkPath: "/dashboard/reviews",
    linkIcon: ReviewsIcon,
  },
  {
    linkName: "Teams",
    linkPath: "/dashboard/teams",
    linkIcon: TeamsIcon,
  },
];

import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Logo from "../../../assets/general/logo.png";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { SimpleLayoutNavConfig } from "./NavConfig";
const Navbar = () => {
  const [closeSideBar, setCloseSideBar] = useState(false);
  useEffect(() => {
    const menu = document.querySelector(".menu-bar");
    const closemenu = document.querySelector(".close-menu");
    const menuList = document.querySelector(".sidebar-container");
    const handleMenuClick = () => {
      menuList.classList.toggle("sidebar-container-active");
    };
    if (closeSideBar) {
      menuList.classList.remove("sidebar-container-active");
      setCloseSideBar(false);
    }
    menu.addEventListener("click", handleMenuClick);
    closemenu.addEventListener("click", handleMenuClick);

    return () => {
      menu.removeEventListener("click", handleMenuClick);
      closemenu.removeEventListener("click", handleMenuClick);
    };
  }, [closeSideBar]);
  return (
    <nav className="nav-container">
      <Link to="/">
        <img className="nav-logo" draggable={false} src={Logo} alt="logo" />
      </Link>
      <ul className="nav-link-container">
        {SimpleLayoutNavConfig.map((navData, index) => {
          return (
            <li key={index}>
              <Link to={navData.link} className="nav-link">
                {navData.name}
              </Link>
            </li>
          );
        })}
        <li>
          <Link to="/contactus" className="nav-link-get-in-touch">
            Get in Touch
          </Link>
        </li>
      </ul>
      <ul className="menu-bar">
        <RxHamburgerMenu />
      </ul>
      <ul className="sidebar-container">
        <div className="side-bar-close-button close-menu">
          <IoClose className="nav-link-caret side-bar-close-button" />
        </div>
        <Link
          to="/"
          className="sidebar-logo"
          onClick={() => {
            setCloseSideBar(true);
          }}
        >
          <img
            className="nav-logo-sidebar"
            draggable={false}
            src={Logo}
            alt="logo"
          />
        </Link>
        <div className="sidebar-content">
          {SimpleLayoutNavConfig.map((navData, index) => {
            return (
              <li key={index}>
                <Link
                  to={navData.link}
                  className="nav-link"
                  onClick={() => {
                    setCloseSideBar(true);
                  }}
                >
                  {navData.name}
                </Link>
              </li>
            );
          })}
          <li>
            <Link
              to="/contactus"
              className="nav-link-get-in-touch"
              onClick={() => {
                setCloseSideBar(true);
              }}
            >
              Get in Touch
            </Link>
          </li>
        </div>
        <div className="sidebar-footer"></div>
      </ul>
    </nav>
  );
};
export default Navbar;

import "./ServicesCard.css";
import ServicesSubSvg from "../../../../../assets/simpleLayout/home/services/Icons/SubIcon.svg";
const ServicesCard = ({
  servicesMainSvg,
  mainHeading,
  subHeading,
  description,
}) => {
  return (
    <div className="services-card-container">
      <div className="services-card-header">
        <div>
          <img
            src={servicesMainSvg}
            alt="icon1"
            className="services-card-main-svg"
          ></img>
        </div>
        <div>
          <img
            src={ServicesSubSvg}
            alt="sub icon"
            className="services-card-sub-svg"
          ></img>
        </div>
      </div>
      <div className="services-card-body">
        <div className="services-card-body-heading">{mainHeading}</div>
        <div className="services-card-body-sub-heading">{subHeading}</div>
        <div className="services-card-body-description">{description}</div>
      </div>
    </div>
  );
};
export default ServicesCard;

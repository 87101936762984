import { useEffect, useState } from "react";
import "./EventsDescriptionLanding.css";
import "./EventDescriptionMoments.css";
import axios from "axios";
import EventDescImg from "../../../../assets/simpleLayout/events/eventDescription.jpg";
const EventsDescription = ({ id }) => {
  const [links, setLinks] = useState([]);
  const [eventData, setEventData] = useState({});
  useEffect(() => {
    axios
      .get("/api/events/" + id)
      .then((res) => {
        setLinks(splitArray(res.data.image));
        setEventData(res.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  }, []);
  function splitArray(arr) {
    let result = [];
    let i = 0;
    while (i < arr.length - 3) {
      result.push(arr.slice(i, i + 2));
      i += 2;
    }
    result.push(arr.slice(i));
    return result;
  }
  return (
    <>
      <section
        className="landing-container-events"
        style={{ maxWidth: "unset" }}
      >
        <img
          src={EventDescImg}
          alt="landing"
          className="event-des-landing-image"
        ></img>
        <div className="events-landing-heading">{eventData?.name}</div>
        <div className="events-landing-description">
          {eventData?.description}
        </div>
      </section>
      <section>
        <div className="events-list-heading">
          <span className="events-list-dark">Our</span>{" "}
          <span className="events-list-color">Moments</span>
        </div>
        <div className="events-image-container">
          {Array.isArray(links)
            ? links.map((image, index) => {
                if (image.length === 2) {
                  if (index % 2 === 0) {
                    return (
                      <div className="ev-des-grid-1">
                        <div>
                          <img
                            src={image[0]}
                            alt="sample"
                            className="ev-des-img"
                          ></img>
                        </div>
                        <div>
                          <img
                            src={image[1]}
                            alt="sample"
                            className="ev-des-img"
                          ></img>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="ev-des-grid-2">
                        <div>
                          <img
                            src={image[0]}
                            alt="sample"
                            className="ev-des-img"
                          ></img>
                        </div>
                        <div>
                          <img
                            src={image[1]}
                            alt="sample"
                            className="ev-des-img"
                          ></img>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div className="ev-des-grid-3">
                      <div>
                        <img
                          src={image[0]}
                          alt="sample"
                          className="ev-des-img"
                        ></img>
                      </div>
                      <div>
                        <img
                          src={image[1]}
                          alt="sample"
                          className="ev-des-img"
                        ></img>
                      </div>
                      <div>
                        <img
                          src={image[2]}
                          alt="sample"
                          className="ev-des-img"
                        ></img>
                      </div>
                    </div>
                  );
                }
              })
            : null}
        </div>
      </section>
    </>
  );
};

export default EventsDescription;

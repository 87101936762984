import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import "./AboutPeople.css";
import PeopleView from "./PeopleView/PeopleView";
const AboutPeople = () => {
  const { data, loadMore, isError } = useInfiniteScroll("/api/teams", 4);
  return (
    !isError && (
      <section id="about-page-who-we-have">
        <div className="about-people-main-heading">
          <span className="about-people-dark-text">We Have</span>
          <br className="about-people-break"></br>
          <span className="about-people-secondary-text">Some Awesome </span>
          <span className="about-people-dark-text">People</span>
        </div>
        <div className="about-people-photo-grid">
          {Array.isArray(data)
            ? data.map((people, index) => {
                return (
                  <PeopleView
                    name={people.firstName}
                    personImage={people.image}
                    position={people.role}
                    facebook={people.facebook}
                    linkedin={people.linkedin}
                    twitter={people.twitter}
                    skype={people.skype}
                  ></PeopleView>
                );
              })
            : null}
        </div>
        {loadMore}
      </section>
    )
  );
};

export default AboutPeople;

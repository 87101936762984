import NoResult from "./noresults.jpg";
import Error from "./error.png";
import "./ExceptionComponent.css";
/**
 * Display exception.
 * @param {('noresult'|'error')} type - The type of the message. Can only be 'A', 'B', or 'C'.
 */
const ExceptionComponent = ({ type }) => {
  if (type === "noresult") {
    return (
      <div className="exception-container">
        <img src={NoResult} alt="no result"></img>
        <span>No Results Found !</span>
      </div>
    );
  }
  if (type === "error") {
    return (
      <div className="exception-container">
        <img src={Error} alt="error"></img>
        <span>Something went wrong !</span>
      </div>
    );
  }
};
export default ExceptionComponent;

import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import axios from "axios";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import { Link, useNavigate } from "react-router-dom";
const EditService = ({ serviceId }) => {
  const dispatch = useDispatch();
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();

  useEffect(() => {
    dispatch(startLoading());
    axios
      .get("/api/services/" + serviceId)
      .then((res) => {
        dispatch(stopLoading());
        setFormData({
          serviceName: res.data.name,
          subtitle: res.data.subtitle,
          description: res.data.description,
          image: [res.data.image],
        });
      })
      .catch((error) => {
        dispatch(stopLoading());
        toast("Error in Fetching Service", "errorToast");
      });
  }, []);
  const navigate = useNavigate();
  const [clearDragAndDrop, setClearDragAndDrop] = useState(false);
  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "serviceName",
        validations: ["required"],
        label: "Service Name",
      },
      {
        name: "image",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Image",
      },
      {
        name: "subtitle",
        validations: ["required"],
        label: "Subtitle",
      },
      {
        name: "description",
        validations: ["required"],
        label: "Description",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = new FormData();
      bodyData.append("name", formData.serviceName);
      bodyData.append("subtitle", formData.subtitle);
      bodyData.append("description", formData.description);
      bodyData.append("image", formData.image[0]);

      try {
        dispatch(startLoading());
        axios
          .put("/api/services/" + serviceId, bodyData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            toast("Service Updated Successfully", "successToast");
            setFormData({
              serviceName: "",
              image: [],
              subtitle: "",
              description: "",
            });
            setClearDragAndDrop(!clearDragAndDrop);
            navigate("/dashboard/services");
            dispatch(stopLoading());
          })
          .catch((err) => {
            toast("Error in Updating Service", "errorToast");
            dispatch(stopLoading());
          });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        dispatch(stopLoading());
      }
    }
  };

  return (
    <>
      <DashboardAddEdit
        heading={"Edit Service"}
        parentLink="/dashboard/services"
        parentName={"Services"}
      >
        <div className="add-services-body">
          <div className="add-service-body-col-1">
            <CustomSingleImageUpload
              // value={formData.image}
              urlArray={formData.image}
              onChange={(value) => {
                handleChange(value, "image");
              }}
              label={"Photo *"}
              dropZoneSizeClassName={"add-service-dropzone-size"}
              error={errors?.image}
              status={clearDragAndDrop}
            ></CustomSingleImageUpload>
          </div>
          <div className="add-service-body-col-2">
            <CustomText
              name="Service Name"
              label={"Service Name *"}
              flex={"1"}
              onChange={(e) => {
                handleChange(e, "serviceName");
              }}
              error={errors?.serviceName}
              value={formData.serviceName}
              placeholder={"Service Name"}
            ></CustomText>
            <CustomText
              name="Subtitle"
              label={"Subtitle *"}
              flex={"1"}
              onChange={(e) => {
                handleChange(e, "subtitle");
              }}
              error={errors?.subtitle}
              value={formData.subtitle}
              placeholder={"Subtitle"}
            ></CustomText>
            <CustomTextArea
              label={"Description *"}
              name="Description"
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "description");
              }}
              value={formData.description}
              error={errors?.description}
              placeholder={"Description"}
            ></CustomTextArea>
          </div>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <Link to="/dashboard/services">
          <button className="add-services-clear-button">Cancel</button>
        </Link>
        <button className="add-services-submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default EditService;

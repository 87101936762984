import { useEffect, useState } from "react";
import "./SmoothScrollToTop.css";
import { IoMdRocket } from "react-icons/io";
const SmoothScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {showButton && (
        <button
          type="button"
          onClick={scrollUp}
          className={`smooth-scroll-button`}
        >
          <IoMdRocket />
        </button>
      )}
    </div>
  );
};
export default SmoothScrollToTop;

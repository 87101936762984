import "./DashboardSummaryDataCard.css";
const DashboardSummaryDataCard = ({ icon, heading, value, iconClass }) => {
  return (
    <div className="dashboard-summary-flex-item">
      <div className={"dashboard-summary-icon-container " + iconClass}>
        {icon}
      </div>
      <div className="dashboard-summary-data">
        <div className="dashboard-summary-heading">{heading}</div>
        <div className="dashboard-summary-value">{value}</div>
      </div>
    </div>
  );
};
export default DashboardSummaryDataCard;

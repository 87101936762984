import { useEffect, useState } from "react";
import BlogPreview from "../../../../components/BlogPreview/BlogPreview";
import "./AllBlogs.css";
import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import HoverAnimate from "../hover/HoverAnimate";
import image1 from "../../../../assets/simpleLayout/allBlogs/landingImages/1.jpeg";
import image2 from "../../../../assets/simpleLayout/allBlogs/landingImages/2.jpeg";
import image3 from "../../../../assets/simpleLayout/allBlogs/landingImages/3.jpeg";
import image4 from "../../../../assets/simpleLayout/allBlogs/landingImages/4.jpeg";
import SearchBox from "../../../../components/SearchBox/SearchBox";
import axios from "axios";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  formatDate,
  isWithinSevenDays,
} from "../../../../functions/DateFunctions";
import ExceptionComponent from "../../../../components/ExceptionComponent/ExceptionComponent";
import SpinnerInside from "../../../../components/SpinnerInside/SpinnerInside";
const AllBlogs = () => {
  const [top5Tags, setTop5Tags] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("all");

  const dispatch = useDispatch();
  const [data, setData] = useState({});
  // Related to Pagination
  const limit = { search: 6, fetch: 6 };
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (page) => setCurrentPage(page);
  // Related to search
  const [mode, setMode] = useState("get");
  const [searchTerm, setSearchTerm] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      // Get Top 5 Tags
      axios
        .get("/api/tags/toptags")
        .then((res) => {
          setTop5Tags(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (mode === "get") {
      if (selectedTopic === "all") {
        setLoading(true);
        axios
          .get("/api/blogs", {
            params: {
              limit: limit.fetch,
              page: currentPage,
            },
          })
          .then((res) => {
            setLoading(false);
            setData(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErrorApi(true);
          });
      } else {
        setLoading(true);
        axios
          .get("/api/tags/filter", {
            params: {
              limit: limit.fetch,
              page: currentPage,
              tag: selectedTopic,
            },
          })
          .then((res) => {
            setLoading(false);
            setData(res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErrorApi(true);
          });
      }
    }
    if (mode === "search") {
      setLoading(true);
      axios
        .get("/api/blogs/search", {
          params: {
            searchTerm: searchTerm,
            limit: limit.search,
            page: currentPage,
          },
        })
        .then((res) => {
          setLoading(false);
          setData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setErrorApi(true);
        });
    }
  }, [currentPage, mode, searchTerm, selectedTopic]);

  return (
    <div>
      <section id="blogs-page-all-blogs">
        <Polygon
          marginLeft={"-180px"}
          marginTop={"80px"}
          width={400}
          height={400}
        />
        <CurvedLines
          width={2000}
          height={400}
          top={"0px"}
          left={"0px"}
          key="1"
        />
        <div className="all-blogs-landing-container">
          <div className="all-blogs-landing-heading-set">
            <div className="all-blogs-whole-heading">
              <span className="all-blogs-landing-color">BLOGS </span>
              <br className="all-blogs-landing-break"></br>
              <span className="all-blogs-landing-black">DISCOVER OUR </span>
              <br className="all-blogs-landing-break"></br>
              <span className="all-blogs-landing-black">LATESTS STORY.</span>
            </div>
            <SearchBox
              className="all-blogs-search-box"
              onChange={(term) => {
                setSearchTerm(term);
                setMode("search");
                setCurrentPage(1);
                setSelectedTopic(term);
              }}
            />
          </div>
          <div className="all-blogs-hover-animate-container">
            {Array.isArray(top5Tags) ? (
              top5Tags.length >= 4 ? (
                <>
                  <HoverAnimate
                    image={image1}
                    topic={top5Tags[0]._id}
                    count={top5Tags[0].count}
                    mobileViewStyle="all-blogs-hover-animate-card-1"
                  />
                  <HoverAnimate
                    image={image2}
                    topic={top5Tags[1]._id}
                    count={top5Tags[1].count}
                    mobileViewStyle="all-blogs-hover-animate-card-2"
                  />
                  <HoverAnimate
                    image={image3}
                    topic={top5Tags[2]._id}
                    count={top5Tags[2].count}
                    mobileViewStyle="all-blogs-hover-animate-card-3"
                  />
                  <HoverAnimate
                    image={image4}
                    topic={top5Tags[3]._id}
                    count={top5Tags[3].count}
                    mobileViewStyle="all-blogs-hover-animate-card-4"
                  />
                </>
              ) : (
                <>
                  <HoverAnimate
                    image={image1}
                    topic={""}
                    count={0}
                    mobileViewStyle="all-blogs-hover-animate-card-1"
                  />
                  <HoverAnimate
                    image={image2}
                    topic={""}
                    count={0}
                    mobileViewStyle="all-blogs-hover-animate-card-2"
                  />
                  <HoverAnimate
                    image={image3}
                    topic={""}
                    count={0}
                    mobileViewStyle="all-blogs-hover-animate-card-3"
                  />
                  <HoverAnimate
                    image={image4}
                    topic={""}
                    count={0}
                    mobileViewStyle="all-blogs-hover-animate-card-4"
                  />
                </>
              )
            ) : (
              <>
                <HoverAnimate
                  image={image1}
                  topic={top5Tags[0]._id}
                  count={top5Tags[0].count}
                  mobileViewStyle="all-blogs-hover-animate-card-1"
                />
                <HoverAnimate
                  image={image2}
                  topic={top5Tags[1]._id}
                  count={top5Tags[1].count}
                  mobileViewStyle="all-blogs-hover-animate-card-2"
                />
                <HoverAnimate
                  image={image3}
                  topic={top5Tags[2]._id}
                  count={top5Tags[2].count}
                  mobileViewStyle="all-blogs-hover-animate-card-3"
                />
                <HoverAnimate
                  image={image4}
                  topic={top5Tags[3]._id}
                  count={top5Tags[3].count}
                  mobileViewStyle="all-blogs-hover-animate-card-4"
                />
              </>
            )}
          </div>
        </div>
        <SpinnerInside loading={loading}>
          <>
            <div className="all-blogs-tag-container">
              <div
                className={
                  "all-blogs-tag " +
                  (selectedTopic === "all" ? "all-blogs-tag-active" : "")
                }
                onClick={() => {
                  setSelectedTopic("all");
                  setMode("get");
                  setCurrentPage(1);
                }}
              >
                All
              </div>
              {Array.isArray(top5Tags) &&
                top5Tags.map((tag, index) => {
                  return (
                    <div
                      className={
                        "all-blogs-tag " +
                        (selectedTopic === tag._id
                          ? "all-blogs-tag-active"
                          : "")
                      }
                      onClick={() => {
                        setMode("get");
                        setCurrentPage(1);
                        setSelectedTopic(tag._id);
                      }}
                    >
                      {tag._id}
                    </div>
                  );
                })}
            </div>
            {errorApi ? (
              <div style={{ margin: "100px 0px 100px 0px" }}>
                <ExceptionComponent type="error"></ExceptionComponent>
              </div>
            ) : Array.isArray(data?.data) ? (
              data?.data.length > 0 ? (
                <>
                  <div className="all-blogs-blog-container">
                    {Array.isArray(data?.data) &&
                      data.data.map((blog, index) => {
                        return (
                          <BlogPreview
                            key={index}
                            isNew={
                              isWithinSevenDays(blog.createdAt) ? true : false
                            }
                            heading={blog.topic}
                            // description={blog.description}
                            readMoreLink={"/blogs/" + blog._id}
                            viewCount={formatDate(blog.createdAt)}
                            image={blog.coverImage}
                          ></BlogPreview>
                        );
                      })}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPage={data?.totalPages}
                    paginate={paginate}
                  />
                </>
              ) : (
                <div style={{ margin: "100px 0px 100px 0px" }}>
                  <ExceptionComponent type="noresult"></ExceptionComponent>
                </div>
              )
            ) : null}
          </>
        </SpinnerInside>
        <div className="all-blogs-bottom-space"></div>
      </section>
    </div>
  );
};
export default AllBlogs;

import React, { useEffect, useState } from "react";
import DashboardAddEdit from "../../../../components/DashboardAddEdit/DashboardAddEdit";
import "./AddBlogs.css";
import "./AddBlogFormatComponent.css";
import AutoTextArea from "./EditComponents/TextArea/AutoTextArea";
// Controller Icons
import { FaHeading } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa6";
import { ImParagraphLeft } from "react-icons/im";
import { IoAddCircle } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import CustomSingleImageUpload from "../../../../components/FormComponents/SingleImageUpload/CustomSingleImageUpload";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import { mergeDateAndTimeToUTC } from "../../../../functions/DateFunctions";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useNavigate } from "react-router-dom";
import {
  startLoading,
  stopLoading,
} from "../../../../redux/actions/loaderActions";
import { useDispatch } from "react-redux";

const AddBlogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // To save blog title
  const [blogTitle, setBlogTitle] = useState("");
  const [blogTags, setBlogTags] = useState("");
  const [blogDate, setBlogDate] = useState("");
  const [blogTime, setBlogTime] = useState("");
  const [coverImage, setCoverImage] = useState([]);
  // Content Validation results of component content
  const [contentValidation, setContentValidation] = useState({});
  // save validation results
  const { errors, handleErrors, toast } = useHandleFormData();
  // Show, Hide Add new component toolbar
  const [showToolBar, setShowToolBar] = useState(false);
  // Array of added components
  const [components, setComponents] = useState([]);
  // Added Number order, Increment 1 after adding new component
  const [componentNumber, setComponentNumber] = useState(1);

  const handleToolbar = () => {
    setShowToolBar(!showToolBar);
  };

  useEffect(() => {
    // Clear errors wheen user starts typing
    handleErrors({});
  }, [blogTitle, blogTags, blogDate, blogTime]);

  const handleContentChange = (componentNum, value, other) => {
    setContentValidation({});
    if (other !== undefined) {
      setComponents((prevComponents) =>
        prevComponents.map((component) =>
          component.number === componentNum
            ? { ...component, [other]: value }
            : component
        )
      );
    } else {
      setComponents((prevComponents) =>
        prevComponents.map((component) =>
          component.number === componentNum
            ? { ...component, value: value }
            : component
        )
      );
    }
  };

  const createNewComponent = (type) => {
    setShowToolBar(false);
    const newComponent = {
      number: componentNumber,
      type: type,
      value: "",
    };
    setComponents((prevComponents) => [...prevComponents, newComponent]);
    setComponentNumber((prevComponentNumber) => prevComponentNumber + 1);
  };

  const handleDeleteComponent = (componentNum) => {
    setComponents((prevComponents) =>
      prevComponents.filter((component) => component.number !== componentNum)
    );
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    setComponents((prevComponents) => {
      const newComponents = [...prevComponents];
      [newComponents[index - 1], newComponents[index]] = [
        newComponents[index],
        newComponents[index - 1],
      ];
      return newComponents;
    });
  };

  const handleMoveDown = (index) => {
    if (index === components.length - 1) return;
    setComponents((prevComponents) => {
      const newComponents = [...prevComponents];
      [newComponents[index], newComponents[index + 1]] = [
        newComponents[index + 1],
        newComponents[index],
      ];
      return newComponents;
    });
  };

  const renderComponent = (component, index) => {
    switch (component.type) {
      case "heading":
        return (
          <div
            key={component.number}
            className="add-blog-single-component-container"
          >
            <div className="add-blog-single-component-element">
              <AutoTextArea
                onChange={(e) =>
                  handleContentChange(component.number, e.target.value)
                }
                value={component.value}
                placeholder={"Heading..."}
                className={"add-blogs-element-common add-blog-heading"}
                error={contentValidation[component.number]}
              />
            </div>
            <div className="add-blog-single-component-controller">
              <FaArrowAltCircleUp
                className="add-blog-single-component-up"
                onClick={() => handleMoveUp(index)}
              />
              <FaArrowAltCircleDown
                className="add-blog-single-component-down"
                onClick={() => handleMoveDown(index)}
              />
              <FaTrash
                onClick={() => {
                  handleDeleteComponent(component.number);
                }}
                className="add-blog-single-component-delete"
              />
            </div>
          </div>
        );
      case "image":
        return (
          <div key={component.number}>
            <div className="add-blog-single-component-container">
              <div className="add-blog-single-component-element">
                <div>
                  <CustomSingleImageUpload
                    error={contentValidation[component.number]}
                    // value={components[component.number - 1].value}
                    label={""}
                    onChange={(image) =>
                      handleContentChange(component.number, image)
                    }
                    dropZoneSizeClassName={"add-blogs-dropzone-size"}
                    status={false}
                  />
                </div>
                <input
                  type="text"
                  placeholder="Image Source"
                  className="add-blogs-element-common add-blog-image-source"
                  onChange={(e) =>
                    handleContentChange(
                      component.number,
                      e.target.value,
                      "imagesource"
                    )
                  }
                ></input>
              </div>
              <div className="add-blog-single-component-controller">
                <FaArrowAltCircleUp
                  className="add-blog-single-component-up"
                  onClick={() => handleMoveUp(index)}
                />
                <FaArrowAltCircleDown
                  className="add-blog-single-component-down"
                  onClick={() => handleMoveDown(index)}
                />
                <FaTrash
                  onClick={() => {
                    handleDeleteComponent(component.number);
                  }}
                  className="add-blog-single-component-delete"
                />
              </div>
            </div>
          </div>
        );
      case "para":
        return (
          <div
            key={component.number}
            className="add-blog-single-component-container"
          >
            <div className="add-blog-single-component-element">
              <AutoTextArea
                onChange={(e) =>
                  handleContentChange(component.number, e.target.value)
                }
                value={component.value}
                placeholder={"Paragraph..."}
                className={"add-blogs-element-common add-blog-paragraph"}
                error={contentValidation[component.number]}
              />
            </div>
            <div className="add-blog-single-component-controller">
              <FaArrowAltCircleUp
                className="add-blog-single-component-up"
                onClick={() => handleMoveUp(index)}
              />
              <FaArrowAltCircleDown
                className="add-blog-single-component-down"
                onClick={() => handleMoveDown(index)}
              />
              <FaTrash
                onClick={() => {
                  handleDeleteComponent(component.number);
                }}
                className="add-blog-single-component-delete"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "title",
        validations: ["required"],
        label: "Blog Title",
      },
      {
        name: "coverImage",
        type: "image",
        count: 1,
        validations: ["required"],
        label: "Cover Image",
      },
      {
        name: "tags",
        validations: ["required"],
        label: "Tags",
      },
      {
        name: "date",
        validations: ["required"],
        label: "Date",
      },
      {
        name: "time",
        validations: ["required"],
        label: "Time",
      },
    ];
    // Validate blog title, tags, date, time
    const validationResults = performValidation(validationConfig, {
      title: blogTitle,
      tags: blogTags,
      date: blogDate,
      time: blogTime,
      coverImage: coverImage,
    });
    // Validate components : required
    if (components.length === 0) {
      validationResults.component = "Please add atlease one component";
    }
    // Validate components: and save results inside the contentValidation with component
    // number and error {1:"Image not found"} and validate para
    // and heading for empty value validation
    let contentValidationResults = {};
    for (let i = 0; i < components.length; i++) {
      if (components[i].type === "heading") {
        if (components[i].value === "") {
          contentValidationResults[components[i].number] =
            "Heading is required";
        }
      }
      if (components[i].type === "para") {
        if (components[i].value === "") {
          contentValidationResults[components[i].number] =
            "Paragraph is required";
        }
      }
      if (components[i].type === "image") {
        if (components[i].value.length === 0) {
          contentValidationResults[components[i].number] = "Image is required";
        } else {
          if (components[i].value.length > 1) {
            contentValidationResults[components[i].number] =
              "Only one image is allowed";
          }
        }
      }
    }
    setContentValidation(contentValidationResults);
    // save output
    handleErrors(validationResults);
    // Validation ends
    // If no errors, then submit the form
    if (
      Object.keys(validationResults).length === 0 &&
      Object.keys(contentValidationResults).length === 0
    ) {
      let formData = new FormData();
      formData.append("topic", blogTitle);
      formData.append("tags", blogTags);
      formData.append("dateTime", mergeDateAndTimeToUTC(blogDate, blogTime));
      formData.append("image", coverImage[0]);
      let updatedContent = components.map((component) => {
        if (component.type === "image") {
          let file = component.value[0];
          formData.append("image", file);
          return { ...component, value: file.name };
        } else {
          return { ...component };
        }
      });
      formData.append("content", JSON.stringify(updatedContent));
      try {
        dispatch(startLoading());
        axios
          .post("/api/blogs", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            dispatch(stopLoading());
            toast("Blog added successfully", "successToast");
            setComponents([]);
            setBlogTitle("");
            setBlogTags("");
            setBlogDate("");
            setBlogTime("");
            setContentValidation({});
            setCoverImage([]);
            navigate("/dashboard/blogs");
          })
          .catch((err) => {
            dispatch(stopLoading());
            toast("Adding Blog Failed", "errorToast");
          });
      } catch (error) {
        dispatch(stopLoading());
        toast("Oops! Something went wrong", "errorToast");
      }
    }
    // else {
    //   toast("Validation failed !", "warningToast");
    // }
  };
  return (
    <>
      <DashboardAddEdit
        heading="Add Blog"
        parentLink="/dashboard/blogs"
        parentName={"Blogs"}
      >
        <div>
          <div className="add-blog-main-details-container">
            <CustomSingleImageUpload
              error={errors?.coverImage}
              label={"Cover Image"}
              onChange={(image) => setCoverImage(image)}
              dropZoneSizeClassName={"add-blogs-dropzone-size"}
              status={false}
              flex={"100%"}
            />
            <CustomText
              label={"Topic"}
              flex={"100%"}
              onChange={(e) => {
                setBlogTitle(e.target.value);
              }}
              value={blogTitle}
              placeholder={"Blog Title"}
              error={errors?.title}
            ></CustomText>
            <CustomText
              label={"Tags"}
              flex={"100%"}
              onChange={(e) => {
                setBlogTags(e.target.value);
              }}
              value={blogTags}
              error={errors?.tags}
              placeholder={"#AI #ImageProcessing"}
              tooltip={
                <div class="tooltip">
                  {" ? "}
                  <span class="tooltiptext">
                    <p>Separate tags with #.</p>
                    <p>Spaces will be removed.</p>
                    <p>All letters will be converted to lowercase.</p>
                    <p>Tags are case-sensitive.</p>
                  </span>
                </div>
              }
            ></CustomText>
            <CustomText
              label={"Date & Time"}
              flex={"1"}
              onChange={(e) => {
                setBlogDate(e.target.value);
              }}
              type="date"
              error={errors?.date}
              value={blogDate}
            ></CustomText>
            <CustomText
              label={<div>&nbsp;</div>}
              flex={"1"}
              onChange={(e) => {
                setBlogTime(e.target.value);
              }}
              type="time"
              error={errors?.time}
              value={blogTime}
            ></CustomText>
            {errors?.component && (
              <div style={{ flex: "100%" }} className="form-error-message">
                {errors?.component}
              </div>
            )}

            <div style={{ flex: "100%" }}>
              {/* All added components will be mapped here */}
              <div className="add-blog-other-components-list">
                {components.map((component, index) =>
                  renderComponent(component, index)
                )}
              </div>
            </div>
            <div style={{ flex: "100%" }}>
              {/* Add Component Controller */}
              <div className="add-blog-other-component-control">
                {showToolBar ? (
                  <IoCloseCircle
                    className="add-blog-add-component"
                    onClick={handleToolbar}
                  />
                ) : (
                  <IoAddCircle
                    className="add-blog-add-component"
                    onClick={handleToolbar}
                  />
                )}
                {showToolBar && (
                  <div className="add-blog-other-components">
                    <FaHeading
                      onClick={() => {
                        createNewComponent("heading");
                      }}
                      className="add-blog-other-components-hover"
                    />
                    <FaRegImage
                      onClick={() => {
                        createNewComponent("image");
                      }}
                      className="add-blog-other-components-hover"
                    />
                    <ImParagraphLeft
                      onClick={() => {
                        createNewComponent("para");
                      }}
                      className="add-blog-other-components-hover"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardAddEdit>
      <div className="add-services-footer">
        <button className="add-services-clear-button">Cancel</button>
        <button onClick={handleSubmit} className="add-services-submit-button">
          Submit
        </button>
      </div>
    </>
  );
};

export default AddBlogs;

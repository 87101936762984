const headerData = [
    {
      name: "Event",
      minWidth: "50px",
      maxWidth: "auto",
      width: "200px",
      textAlign: "left",
      color: "#303972",
      fontSize: "var(--font-14-px)",
      tdStyle: {
        fontWeight: "700",
        color: "#303972",
        fontSize: "var(--font-18-px)",
      },
    },
    {
      name: "Date",
      minWidth: "50px",
      maxWidth: "auto",
      width: "200px",
      textAlign: "left",
      color: "#303972",
      fontSize: "var(--font-14-px)",
      tdStyle: {
        fontWeight: "400",
        color: "#A098AE",
        fontSize: "var(--font-14-px)",
      },
    },
    {
      name: "Venue",
      minWidth: "50px",
      maxWidth: "auto",
      width: "200px",
      textAlign: "left",
      color: "#303972",
      fontSize: "var(--font-14-px)",
      tdStyle: {
        fontWeight: "400",
        color: "#A098AE",
        fontSize: "var(--font-14-px)",
      },
    },
    {
      name: "Action",
      minWidth: "50px",
      maxWidth: "auto",
      width: "200px",
      textAlign: "center",
      color: "#303972",
      fontSize: "var(--font-14-px)",
      tdStyle: {
        fontWeight: "400",
        color: "##A098AE",
        fontSize: "var(--font-14-px)",
      },
    },
  ];
  
  const caption = "";
  
  export { headerData, caption };
  
import Icon1 from "../../../../assets/simpleLayout/home/benefits/icons/Icon1.svg";
import Icon2 from "../../../../assets/simpleLayout/home/benefits/icons/Icon2.svg";
import Icon3 from "../../../../assets/simpleLayout/home/benefits/icons/Icon3.svg";
import Icon4 from "../../../../assets/simpleLayout/home/benefits/icons/Icon4.svg";

export const BenefitsData = [
  {
    id: 1,
    heading: "Time zones ain't no thing",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat.",
    icon: Icon1,
  },
  {
    id: 2,
    heading: "Full spectrum of services",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat.",
    icon: Icon2,
  },
  {
    id: 3,
    heading: "Impossible? We're on it",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat.",
    icon: Icon3,
  },
  {
    id: 4,
    heading: "Flexible work terms",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis et sed nam sem tellus erat.",
    icon: Icon4,
  },
];

import "./Service.css";
import LandingImage from "../../../assets/simpleLayout/services/landing.png";
import Polygon from "../../../components/Polygon/Polygon";
import OurServicesCard from "./OurServicesCard/OurServicesCard";
import CurvedLines from "../../../components/SimpleLayout/CurvedLines/CurvedLines";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
const Services = () => {
  const { data, loadMore, isError } = useInfiniteScroll("/api/services", 3);

  return (
    <>
      <section id="services-landing">
        <div className="additional-styling-services-landing">
          <CurvedLines
            width={2000}
            height={400}
            top={"0px"}
            left={"0px"}
            key="1"
          />
          <Polygon
            marginLeft={"-180px"}
            marginTop={"70px"}
            width={400}
            height={400}
          />
          <div className="services-polygon2">
            <Polygon
              marginLeft={"440px"}
              marginTop={"-350px"}
              width={400}
              height={400}
            />
          </div>
        </div>
        <div className="services-page-landing-container">
          <div className="services-page-whole-headings">
            <div className="services-page-sub-heading">TECH SERVICES</div>
            <span className="services-page-main-heading-dark-color">
              Customized <br className="services-page-break"></br>Solutions to{" "}
              <br className="services-page-break"></br>Drive Your
              <br className="services-page-break"></br>
            </span>
            <span className="services-page-main-heading-primary-color">
              Success
            </span>
          </div>
          <img
            src={LandingImage}
            alt="landing"
            className="services-landing-image"
          />
        </div>
        <div style={{ marginBottom: "50px" }}></div>
      </section>
      {!isError && (
        <section id="services-our-services">
          <div className="service-our-polygons">
            <Polygon
              marginLeft={"-40px"}
              marginTop={"770px"}
              width={400}
              height={400}
            />
            <Polygon
              marginLeft={"990px"}
              marginTop={"1100px"}
              width={400}
              height={400}
            />
          </div>
          <div className="services-page-sub-heading-set">
            <span className="service-page-heading-underline"></span>
            <span className="services-our-services-sub-heading">
              Our Services
            </span>
            <span className="service-page-heading-underline"></span>
          </div>

          <div className="services-our-services-main-heading">What We Do</div>
          <div className="services-our-services-grid">
            {Array.isArray(data)
              ? data.map((service, index) => {
                  return (
                    <OurServicesCard
                      Icon={service.image}
                      mainHeading={service.name}
                      subHeading={service.subtitle}
                      description={service.description}
                    ></OurServicesCard>
                  );
                })
              : null}
            
          </div>
          {loadMore}
        </section>
      )}
    </>
  );
};
export default Services;

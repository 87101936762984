import Polygon from "../../../../components/Polygon/Polygon";
import CurvedLines from "../../../../components/SimpleLayout/CurvedLines/CurvedLines";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import "./AllEvents.css";
import EventsGalleryCard1 from "./EventsGalleryCard/EventsGalleryCard1/EventsGalleryCard1";
import EventGalleryCard2 from "./EventsGalleryCard/EventsGalleryCard2/EventsGalleryCard2";
const AllEventsGallery = () => {
  const { data, loadMore, isError } = useInfiniteScroll("/api/events", 2);
  return (
    !isError && (
      <section id="events-all-gallery">
        <div className="events-all-gallery-styles">
          <Polygon
            marginLeft={"-160px"}
            marginTop={"720px"}
            width={500}
            height={500}
          />
          <Polygon
            marginLeft={"300px"}
            marginTop={"420px"}
            width={400}
            height={400}
          />
          <div style={{ position: "relative" }}>
            <CurvedLines
              width={2500}
              height={700}
              top={"1080px"}
              left={"150px"}
              cliptop={"150px"}
              key="3"
              color={"#F2F2F2"}
            />
          </div>
        </div>
        <div className="all-events-gallery-heading">
          <span className="all-events-gallery-heading-dark">
            Journey Through
          </span>
          <br></br>
          <span className="all-events-gallery-heading-color">Our Gallery</span>
        </div>
        <div className="all-event-cards">
          {Array.isArray(data)
            ? data.map((eve, index) => {
                if (eve.image.length <= 3) {
                  return (
                    <EventsGalleryCard1
                      key={index}
                      data={eve}
                      order={index % 2 === 0 ? "left" : "right"}
                    />
                  );
                } else {
                  return (
                    <EventGalleryCard2
                      key={index}
                      data={eve}
                      order={index % 2 === 0 ? "left" : "right"}
                    />
                  );
                }
              })
            : null}
          {loadMore}
        </div>
      </section>
    )
  );
};
export default AllEventsGallery;

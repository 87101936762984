import React, { useEffect, useState } from "react";
import "./Header.css";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import Logout from "./Logout/Logout";
const Header = () => {
  const navigate = useNavigate();
  let localstorageUser = localStorage.getItem("token");
  let user = "";
  if (localstorageUser) {
    user = jwt_decode(localstorageUser);
  } else {
    navigate("/login");
  }
  const location = useLocation();
  const [heading, setHeading] = useState("");
  useEffect(() => {
    const pathElements = location.pathname.split("/");
    // console.log(pathElements);
    let headerWord = "";
    if (pathElements[2]) {
      headerWord = pathElements[2];
    } else {
      headerWord = "";
    }
    // console.log(headerWord);
    if ([].includes(headerWord.toLowerCase())) {
      setHeading("Dashboard");
    } else if (headerWord.toLowerCase() === "services") {
      setHeading("Services");
    } else if (headerWord.toLowerCase() === "career") {
      setHeading("Career");
    } else if (headerWord.toLowerCase() === "events") {
      setHeading("Events");
    } else if (headerWord.toLowerCase() === "portfolio") {
      setHeading("Portfolio");
    } else if (headerWord.toLowerCase() === "blogs") {
      setHeading("Blog Details");
    } else if (headerWord.toLowerCase() === "reviews") {
      setHeading("Reviews");
    } else if (headerWord.toLowerCase() === "teams") {
      setHeading("Teams");
    } else {
      setHeading("Dashboard");
    }
  }, [location.pathname]);
  return (
    <div className="dashboard-header-container">
      <div className="dashboard-header-heading">{heading}</div>
      <div>
        <div className="dashboard-header-loggedin-user">
          <div className="dashboard-header-loggedin-user-data">
            <div className="dashboard-header-loggedin-user-name">
              {user?.firstName || "User"}
            </div>
            <div className="dashboard-header-loggedin-user-role">Admin</div>
          </div>
          <div className="dashboard-header-loggedin-user-image-container">
            <Logout />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
